import React, { Component } from 'react';
import httpService from '../api/HttpService';
import { Organization } from '../models/organization';
import { Link } from 'react-router-dom';
import { OrganizationPublicDTO } from '../models/organization-public-dto';
import { Button, Col, Row } from 'react-bootstrap';
import { SendMessageModal } from './send-message-modal';
import { useState } from 'react';


interface Props {

}

export const Home = (props: Props) => {
    let [showRequest, setShowRequest] = useState(false);

    return (
        <div>
            <div className="promotion">
                <div className="header">
                    <h1>Welcome to 147 board</h1>
                </div>
                <p >147 Board is a powerful scoreboard solution for snooker that looks great, whether you have it at home, in your club, or at your venue. It is easy to use, and has built-in livescoring that doesn't require any setup.
                    The scoreboard comes in <Link to="/pricing">three different packages</Link>, so you only get what you need.
                </p>
                <div className=" mt-2 d-flex">
                    <Link className="btn btn-primary mr-5" to="/register">30 day free trial</Link>
                    <Button variant='outline-primary' onClick={() => setShowRequest(true)}>Request a demo</Button>

                </div>
                <Row className="promotion-row">
                    <Col md={8}>
                        <a href="/match-standard-layout-normal-mode_framed.png" target="_blank" rel="noopener noreferrer"><img src={"/match-standard-layout-normal-mode_framed.png"} className="img-fluid" /></a>
                    </Col>
                    <Col md={4}>
                        <div className="header">
                            <h2>Scoreboard app</h2>
                        </div>
                        <ul>
                            <li><Link to="/about">Runs on Windows 10</Link></li>
                            <li>Easy to use - just enter the amount scored</li>
                            <li>Supports up to 5 players at the same time</li>
                            <li>In marker mode you can enter each ball as it is potted</li>
                        </ul>
                        <div className="d-flex justify-content-center mt-2">
                            <Link className="btn btn-primary" to="/register">Get started</Link>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <Link className="btn btn-outline-primary" to="/pricing">Pricing</Link>
                        </div>
                    </Col>
                </Row>
                <Row className="promotion-row">
                    <Col md={4}>
                        <div className="header">
                            <h2>Livescore</h2>
                        </div>
                        <ul>
                            <li>Watch livescores from all your tables</li>
                            <li>Livescore is automatically updated when you use the scoreboard</li>
                            <li>You get a dedicated URL to your livescore page that is easy to share</li>
                            <li>Looks great both on mobile and computers</li>
                        </ul>
                    </Col>
                    <Col md={8}>
                        <a href="/livescore_framed.png" target="_blank" rel="noopener noreferrer"><img src={"/livescore_framed.png"} className="img-fluid" /></a>
                    </Col>
                </Row>
                <Row className="promotion-row">
                    <Col md={8}>
                        <a href="https://www.youtube.com/watch/Lh2fXxIw95g?start=14982" target="_blank" rel="noopener noreferrer"><img src={"/stream-overlay.png"} className="img-fluid" /></a>
                    </Col>
                    <Col md={4}>
                        <div className="header">
                            <h2>Stream overlay</h2>
                        </div>
                        <ul>
                            <li>Add livescore directly to your streaming video</li>
                            <li>The stream overlay is adjustable so it fits your specific setup</li>
                            <li>It is available for all your tables so you can stream them all at the same time</li>
                            <li><a href="https://www.youtube.com/watch/Lh2fXxIw95g?start=14982" target="_blank" rel="noopener noreferrer"> See it in action</a></li>
                        </ul>
                    </Col>
                </Row>
            </div>
            <SendMessageModal title="Request a demo" show={showRequest} onClose={() => setShowRequest(false)}></SendMessageModal>
        </div>
    );

}