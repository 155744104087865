import React from "react";
import { Component } from "react";
import { Button } from "react-bootstrap";
import authService from "../api/AuthorizeService";
import httpService from "../api/HttpService";
import { Organization } from "../models/organization";

interface Props {
    organizationId: string;
}

interface State {
   organization?: Organization;
}


export class PaymentFailedBanner extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {};
    }


    componentDidMount() {
        this.loadOrganization();
    }

    private loadOrganization = async () => {
        const url = `api/organizations/${this.props.organizationId}`;
        console.log(url);
        let org = await httpService.get<Organization>(url);

        this.setState({ organization: org });
    }

    private manageBilling = async () => {
        const token = authService.getBearerToken();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        };

        const response = await fetch(`${httpService.BaseUrl}api/payments/create-customer-portal-session?orgId=${this.props.organizationId}`, requestOptions);
        if (response.status === 201) {
            const data = await response.json();
            window.location.href = data.url;
        }
        else {
            alert("Something went wrong. Contact info@147board.com for assistance.")
        }
    }

    render() {
        return (
            <div>
                  { this.state.organization && this.state.organization.stripeCustomerId && this.state.organization.paymentStatus !== 'paid' &&
                    <div className="mt-4 alert alert-warning fade show">
                        <span className="mr-4"><strong>Last payment failed.</strong> Please update your payment info </span>
                        <Button variant="secondary" onClick={this.manageBilling}>Update payment</Button>
                    </div>
                }
            </div>
        )
    }
}