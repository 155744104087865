import React, { Component } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

interface Props extends RouteComponentProps<MatchParams> {

}

interface MatchParams {
    session_id: string;
}

interface State {
    sessionId?: string;
}

export class OrderCompleted extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.getSessionId();
    }

    private getSessionId() {
        let urlSearch = new URLSearchParams(this.props.location.search);
        if (urlSearch.has("session_id")) {

            this.setState({ sessionId: urlSearch.get("session_id")! });
        }
    }

    render() {
        return (
            <div>
                <div className="header">
                    <h1>Order completed</h1>
                </div>
                <p>Thank you for subscribing!</p>
                <p><Link to="/login">Click here</Link> to log in and get started.</p>
                <p>Visit the <Link to="/download">Download page</Link> to download the scoreboard on your computers.</p>
            </div>
        );
    }
}