import React, { Component } from 'react';
import httpService from '../api/HttpService';
import { LiveScore } from '../models/livescore';
import Container from 'react-bootstrap/Container';
import { LivescoreCard } from './livescore-card';
import Button from 'react-bootstrap/Button';
import { RouteComponentProps } from 'react-router-dom';
import authService from '../api/AuthorizeService';
import { MatchResult } from '../models/match-result';
import { MatchReport } from './match-report';
import { Col, Form, Row } from 'react-bootstrap';
import moment from 'moment';

interface MatchParams {
    id: string;
}

interface Props extends RouteComponentProps<MatchParams> {

}

interface State {
    isReady: boolean;
    liveScores: LiveScore[];
    refreshCounter: number;
    organizationId: string;
    results: MatchResult[];
    hasResults: boolean;
    resultsDate: string;
}

export class Live extends Component<Props, State> {
    private _apiPath: string = 'api/LiveScores';
    private interval: NodeJS.Timeout;
    private updateInterval: number = 10;

    constructor(props: Props) {
        super(props);
        this.interval = setInterval(() => console.log(), 100000);

        let orgId: string = this.props.match.params.id;

        if (orgId == null) {
            orgId = authService.getUser().organizationId!;
        }

        var now = new Date();
        var dateString = moment(now).format('YYYY-MM-DD');

        console.log(`Org id: ${orgId}`);
        console.log(dateString);
        this.state = {
            isReady: false,
            liveScores: [],
            results: [],
            refreshCounter: this.updateInterval,
            organizationId: orgId,
            resultsDate: dateString,
            hasResults: false
        };
    }

    componentDidMount() {
        this.loadData();
        // this.loadHasResults();
        this.loadResults();
        clearInterval(this.interval);
        this.interval = setInterval(() => this.refreshCounter(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        let updateText = this.state.refreshCounter <= 0 ? "Updating..." : `Updates in ${this.state.refreshCounter}...`;
        let organizationContent = this.state.liveScores.length > 0 ? <h4>{this.state.liveScores[0].organizationName}</h4> : <div></div>
        let content = "<h2>No live matches at the moment</h2>";

        let roundName = "";
        let tournamentName = "";

        return (
            <Container>
                <div className="header">
                    <h1>Livescores</h1>
                </div>
                {organizationContent}

                {this.state.liveScores.length > 0 ? this.state.liveScores.map((livescore: LiveScore) =>
                    <LivescoreCard liveScore={livescore} key={livescore.licenseEntityId} />
                ) :
                    <h4>No live matches at the moment</h4>
                }
                <p><em>{updateText}</em></p>
                <Button onClick={this.loadData}>Refresh</Button>
                <div style={{ height: "20px" }}></div>



                {this.state.hasResults &&
                    <>
                        <div className="header">
                            <h1>Results</h1>
                        </div>
                        <Form>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">Select date</Form.Label>
                                <Col sm="2">
                                    <Form.Control type="date" value={this.state.resultsDate} onChange={(e) => {
                                        this.setState({ resultsDate: e.target.value }, this.loadResults);
                                    }} />
                                </Col>
                                <Col sm="2">
                                    <Button onClick={this.loadResults}>Refresh</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                        {this.state.results.map(result => {
                            const showRoundname = false; // roundName !== result.roundName;
                            roundName = result.roundName;

                            const showTournamentname = tournamentName !== result.tournamentName;
                            tournamentName = result.tournamentName;
                            return <>
                                {showTournamentname && tournamentName && <h4>{result.tournamentName}</h4>}
                                {showRoundname && roundName && <div className='round-name'>{result.roundName}</div>}
                                <MatchReport matchResult={result} minHighBreak={10} />

                            </>
                        })}

                    </>
                }
            </Container>
        );
    }


    private refreshCounter = async (): Promise<void> => {
        if (this.state.refreshCounter <= 0) {
            this.setState({
                refreshCounter: this.updateInterval
            });
            await this.loadData();
        }
        else {
            this.setState({
                refreshCounter: this.state.refreshCounter - 1
            });
        }
    }

    private loadHasResults = async (): Promise<void> => {
        try {
            let res = await httpService.anonFetch(`api/MatchResults/?organizationId=${this.state.organizationId}&hasEntries=true`);
            this.setState({
                hasResults: res.ok
            });
        }
        catch (Exception) {
            console.log(Exception);
            this.setState({
                hasResults: false
            });
        }
    }

    private loadResults = async (): Promise<void> => {
        try {
            let res = await httpService.anonFetch(`api/MatchResults/?organizationId=${this.state.organizationId}&hasEntries=true`);
            this.setState({
                hasResults: res.ok
            });
            if (res.ok) {
                const matches = await httpService.get<MatchResult[]>(`api/MatchResults/?organizationId=${this.state.organizationId}&date=${this.state.resultsDate}`);

                this.setState({
                    results: matches
                });
            }
        }
        catch (Exception) {
            console.error(Exception);
        }
    }

    private loadData = async (): Promise<void> => {
        try {
            let liveScores = await httpService.anonGet<LiveScore[]>(`${this._apiPath}?organizationId=${this.state.organizationId}`);
            let recentTime = new Date(Date.now() - 30 * 60 * 1000);

            // TODO: Use proper time zone handling for filtering old livescores
            // liveScores = liveScores.filter(x => new Date(x.updatedAt) >= recentTime).sort((a, b) => (a.tableName > b.tableName) ? 1 : ((b.tableName > a.tableName) ? -1 : 0));
            this.setState({
                isReady: true,
                liveScores: liveScores
            });
        }
        catch (Exception) {
            console.error(Exception);
        }
    }
}