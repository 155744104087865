import React, { FocusEventHandler, MouseEventHandler, useReducer, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { LicenseEntity } from '../models/license-entity';
import { Col, Row } from 'react-bootstrap';
import httpService from '../api/HttpService';
import { FocusEvent } from 'react'
import { StreamTable } from './stream-table';
import { initialLivescoreLayout, ShowStream } from './show-stream';
import { useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';
import { ColorSelector, CompositeSelector, FontSelector } from './selectors';
import { LivescoreLayout } from '../models/livescore-layout';

interface Props {
    onClose?: () => void;
    license?: LicenseEntity;
}

export const StreamOverlayModal = (props: Props) => {
    const { license } = props;
    let [delay, setDelay] = useState(0);
    let [shorten, setShorten] = useState(false);
    let [uppercase, setUppercase] = useState(false);
    let [isCopied, setIsCopied] = useState(false);
    let [isAltCopied, setIsAltCopied] = useState(false);
    let [search, setSearch] = useState(`?shorten=${shorten ? 1 : 0}&upperCase=${uppercase ? 1 : 0}&delay=${delay}`);
    let [previewWidth, setPreviewWidth] = useState(1200);
    const [saved, setSaved] = useState(false);

    const [color, setColor] = useState("#FF0000");
    const [color1, setColor1] = useState("#FF0000");
    const [color2, setColor2] = useState("#FF0000");

    const [stateKey, setStateKey] = useState(0);

    const [liveLayout, setLiveLayout] = useState<LivescoreLayout>(initialLivescoreLayout);
    const [showSavePreview, setShowSavePreview] = useState(false);

    const link = `${httpService.getBaseUrlForLink()}stream/${props.license?.id}${search}`;
    const altLink = `${httpService.getBaseUrlForLink()}overlay.html${search}&width=${previewWidth}&table=${props.license?.id}`;

    const handleFocus = (event: FocusEvent<any>) => event.target.select();
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link);
        setIsCopied(true);
    };

    const copyAltToClipboard = () => {
        navigator.clipboard.writeText(altLink);
        setIsAltCopied(true);
    };

    const close = () => {
        setIsCopied(false);
        if (props.onClose) {
            props.onClose();
        }
    }

    useEffect(() => {
        const loadLivescoreLayout = async () => {
            if (license) {
                try {
                    const response = await httpService.get<LivescoreLayout>(`api/livescoreLayouts?licenseEntityId=${license.id}`);
                    if (response) {
                        setLiveLayout(response);
                        setStateKey(stateKey + 1);
                    }
                } catch (error) {

                }
            }
        };
        loadLivescoreLayout();
    }, [license]);

    useEffect(() => {
        setSaved(false);
    }, [liveLayout]);

    useEffect(() => {
        setSearch(`?shorten=${shorten ? 1 : 0}&upperCase=${uppercase ? 1 : 0}&delay=${delay}`);
    }, [shorten, uppercase, delay]);

    const clickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        console.log("click outside");
    }

    const resetToDefault = () => {
        setLiveLayout(initialLivescoreLayout);
        setStateKey(stateKey + 1);

        setShowSavePreview(true);
    }

    const saveLayout = async (layout: LivescoreLayout): Promise<LivescoreLayout> => {
        const response = await httpService.post<LivescoreLayout>(`api/livescoreLayouts`, layout);
        setSaved(true);
        return response;
    }

    const saveClick = () => {
        setShowSavePreview(true);
    };

    const closePreviewChanges = () => {
        setShowSavePreview(false);
    }

    return (
        <div>
            {
                props.license ?
                    <Modal dialogClassName="stream-modal" show={props.license} centered={true} onHide={close}>
                        <Modal.Header closeButton>
                            <Modal.Title>Stream overlay for {props.license.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={3}>
                                    <label>Delay (in seconds):</label>
                                </Col>
                                <Col md={2}>
                                    <input type="number" className="form-control" placeholder="Delay" aria-label="License name" aria-describedby="basic-addon1" value={delay} onChange={e => setDelay(parseInt(e.target.value))} />
                                </Col>
                                <Col md={3}>
                                    <label>Shorten first name:</label>
                                </Col>
                                <Col md={1}>
                                    <input className="form-check-input" checked={shorten} onClick={() => setShorten(!shorten)} type="checkbox"></input>

                                </Col>
                                <Col md={2}>
                                    <label>Uppercase:</label>
                                </Col>
                                <Col md={1}>
                                    <input className="form-check-input" checked={uppercase} onClick={() => setUppercase(!uppercase)} type="checkbox"></input>

                                </Col>
                            </Row>


                            <p className="mt-4">Copy the link below and paste it into the browser plugin in your broadcasting software (clicking it will automatically copy it to your clipboard):</p>

                            <div className="d-flex">
                                <input type="text" className="form-control" style={{ fontSize: 13 }} aria-label="License name" aria-describedby="basic-addon1" onClick={copyToClipboard} onFocus={handleFocus} value={link} />
                                <img height={28} src="/content-copy.png" className="ml-2" onClick={copyToClipboard}></img>
                            </div>
                            <div style={{ height: 20, margin: 10 }}>
                                {isCopied &&
                                    <b>Copied to clipboard!</b>
                                }
                            </div>
                            <p className="mt-4">For mobile streaming:</p>

                            <div className="d-flex">
                                <input type="text" className="form-control" style={{ fontSize: 13 }} aria-label="License name" aria-describedby="basic-addon1" onClick={copyAltToClipboard} onFocus={handleFocus} value={altLink} />
                                <img height={28} src="/content-copy.png" className="ml-2" onClick={copyAltToClipboard}></img>
                            </div>
                            <div style={{ height: 20, margin: 10 }}>
                                {isAltCopied &&
                                    <b>Copied to clipboard!</b>
                                }
                            </div>
                            { /* adding a key allows us to redraw this UI block when we change the key */}
                            <div className='stream-preview' onClick={(e) => clickOutside(e)} key={stateKey}>
                                <h4>Customize layout</h4>
                                <Row>
                                    <Col md={4}>
                                        <CompositeSelector
                                            header='Player name'
                                            initialFont={liveLayout.playerTextFont}
                                            initialFontSize={liveLayout.playerTextSize}
                                            initialTextColor={liveLayout.playerTextColorHex}
                                            initialBackColor={liveLayout.playerBgColorHex}
                                            onFontChange={f => setLiveLayout({ ...liveLayout, playerTextFont: f })}
                                            onFontSizeChange={size => setLiveLayout({ ...liveLayout, playerTextSize: size })}
                                            onTextColorChange={c => setLiveLayout({ ...liveLayout, playerTextColorHex: c })}
                                            onBackColorChange={c => setLiveLayout({ ...liveLayout, playerBgColorHex: c })}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <CompositeSelector
                                            header='Player score'
                                            initialFont={liveLayout.playerScoreTextFont}
                                            initialFontSize={liveLayout.playerScoreTextSize}
                                            initialTextColor={liveLayout.playerScoreTextColorHex}
                                            initialBackColor={liveLayout.playerScoreBgColorHex}
                                            onFontChange={f => setLiveLayout({ ...liveLayout, playerScoreTextFont: f })}
                                            onFontSizeChange={size => setLiveLayout({ ...liveLayout, playerScoreTextSize: size })}
                                            onTextColorChange={c => setLiveLayout({ ...liveLayout, playerScoreTextColorHex: c })}
                                            onBackColorChange={c => setLiveLayout({ ...liveLayout, playerScoreBgColorHex: c })}
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <CompositeSelector
                                            header='Frame'
                                            initialFont={liveLayout.frameTextFont}
                                            initialFontSize={liveLayout.frameTextSize}
                                            initialTextColor={liveLayout.frameTextColorHex}
                                            initialBackColor={liveLayout.frameBgColorHex}
                                            onFontChange={f => setLiveLayout({ ...liveLayout, frameTextFont: f })}
                                            onFontSizeChange={size => setLiveLayout({ ...liveLayout, frameTextSize: size })}
                                            onTextColorChange={c => setLiveLayout({ ...liveLayout, frameTextColorHex: c })}
                                            onBackColorChange={c => setLiveLayout({ ...liveLayout, frameBgColorHex: c })}
                                        />
                                    </Col>
                                </Row>
                                <ColorSelector label='Indicator:' initialColor={liveLayout.selectedPlayerColorHex} onColorChange={f => setLiveLayout({ ...liveLayout, selectedPlayerColorHex: f })} />
                                <div>
                                    <p></p>
                                    <section>Happy with how the livescore looks? Remember to click <b>Save layout</b> to use it on stream overlays.</section>
                                    <section>You can always go back to the original by clicking on <b>Reset to default</b>.</section>

                                </div>
                                <Row>
                                    <Col>
                                        <div className='custom-layout-buttons'>
                                            <Button variant="primary" onClick={saveClick}>
                                                Save layout
                                            </Button>
                                            <Button variant="secondary" onClick={resetToDefault}>
                                                Reset to default
                                            </Button>
                                            {saved &&
                                                <div>
                                                    <b>Saved!</b>
                                                </div>}
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="stream-preview" >
                                <h4>Preview</h4>

                                <div className="mt-4">
                                    <div className="zoomed" style={{ width: previewWidth }}>
                                        <ShowStream tableId={props.license.id} delay={delay} shorten={shorten} upperCase={uppercase}
                                            livescoreLayout={liveLayout}
                                        ></ShowStream>
                                    </div>
                                </div>
                                <Row>
                                    <Col md={3}>
                                        <label>Preview width:</label>
                                    </Col>
                                    <Col md={3}>
                                        <input type="number" className="form-control" placeholder="Font size" aria-label="License name" aria-describedby="basic-addon1" value={previewWidth} onChange={e => setPreviewWidth(parseInt(e.target.value))} />
                                    </Col>
                                </Row>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                                Close
                            </Button>
                        </Modal.Footer>
                        {showSavePreview && license &&
                            <SaveChangesPreviewModal
                                layout={liveLayout}
                                license={license}
                                width={previewWidth}
                                onClose={closePreviewChanges}
                                onSubmit={() => { saveLayout(liveLayout); closePreviewChanges(); }}
                            />
                        }
                    </Modal>
                    :
                    <div></div>
            }
        </div>

    );
};

interface LayoutPreviewProps {
    layout: LivescoreLayout;
    license: LicenseEntity;
    onClose: () => void;
    onSubmit: () => void;
    width?: number;
}

const SaveChangesPreviewModal: React.FC<LayoutPreviewProps> = ({ layout, license, onClose, onSubmit, width }) => {
    const [existingLayout, setExistingLayout] = useState<LivescoreLayout>();

    useEffect(() => {
        const loadLivescoreLayout = async () => {
            if (license) {
                try {
                    const response = await httpService.get<LivescoreLayout>(`api/livescoreLayouts?licenseEntityId=${license.id}`);
                    if (response) {
                        setExistingLayout(response);
                    }
                } catch (error) {

                }
            }
        };
        loadLivescoreLayout();
    }, [license]);

    return (
        <Modal dialogClassName="stream-preview-changes-modal" show centered onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Preview changes</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div>
                    <h4>Current layout</h4>
                    <div className="zoomed" style={{ width: width }}>
                        <ShowStream tableId={license.id} delay={0} shorten={false} upperCase={false} livescoreLayout={existingLayout} />
                    </div>
                    <div className='stream-preview-changes-arrow'>
                        <img height={60} src="/down_arrow.png" className="ml-2"></img>
                    </div>
                    <h4>New layout</h4>
                    <div className="zoomed" style={{ width: width }}>
                        <ShowStream tableId={license.id} delay={0} shorten={false} upperCase={false} livescoreLayout={layout} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <p>Are your sure you want to replace the <b>current layout</b> with the <b>new layout</b>?</p>
                <Button variant='primary' onClick={onSubmit}>Confirm</Button>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>

    );
};