import React, { Component } from 'react';
import { UIConfig } from '../models/uiConfig';


interface Props {
    skin: UIConfig
}

interface State {
  
}

export class SkinPreview extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div>
                <h4>{this.props.skin.name}</h4>
                
                <button type="button" className="btn btn-primary btn-sm">
                    Save
                </button>
            </div>
        );
    }
}