import React, { useState } from 'react';
import { HexColorPicker } from "react-colorful";

interface ColorSelectorProps {
    label: string;
    initialColor?: string;
    onColorChange?: (color: string) => void;
}

export const ColorSelector: React.FC<ColorSelectorProps> = ({ label, onColorChange, initialColor = "#aabbcc" }) => {
    const [color, setColor] = useState(initialColor);
    const [showPicker, setShowPicker] = useState(false);

    const handleBoxClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        setShowPicker(!showPicker);
    };

    const changeColor = (color: string) => {
        setColor(color);
        onColorChange && onColorChange(color);
    };

    return (
        <div className='color-selector-container'>
            <label>{label}</label>
            <input type='text' value={color} onChange={e => changeColor(e.target.value)} />
            <section
                onClick={handleBoxClick}
                style={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: color,
                    border: '1px solid #000',
                    cursor: 'pointer'
                }}
            />
            {showPicker && (
                <div className='small-color-picker'>
                    <HexColorPicker color={color} onChange={changeColor}  onClick={e => e.stopPropagation()}/>
                </div>
            )}
        </div>
    );
};

interface FontPickerProps {
    initialFont?: string;
    onFontChange?: (font: string) => void;
}


const FontPicker: React.FC<FontPickerProps> = ({ onFontChange, initialFont = "Arial" }) => {
    const [font, setFont] = useState(initialFont);

    const changeFont = (font: string) => {
        setFont(font);
        onFontChange && onFontChange(font);
    };

    return (
        <select value={font} onChange={e => changeFont(e.target.value)}>
            <option value="Calibri" style={{ fontFamily: 'Calibri' }}>Calibri</option>
            <option value="Roboto" style={{ fontFamily: 'Roboto' }}>Roboto</option>
            <option value="Roboto Condensed" style={{ fontFamily: 'Roboto Condensed' }}>Roboto Condensed</option>
            <option value="Open Sans" style={{ fontFamily: 'Open Sans' }}>Open Sans</option>
            <option value="Montserrat" style={{ fontFamily: 'Montserrat' }}>Montserrat</option>
            <option value="Itim" style={{ fontFamily: 'Itim' }}>Itim</option>
            <option value="Oswald" style={{ fontFamily: 'Oswald' }}>Oswald</option>
            <option value="Tiny5" style={{ fontFamily: 'Tiny5' }}>Tiny5</option>
            <option value="Anton SC" style={{ fontFamily: 'Anton SC' }}>Anton SC</option>
            <option value="Playfair Display" style={{ fontFamily: 'Playfair Display' }}>Playfair Display</option>
            <option value="Domine" style={{ fontFamily: 'Domine' }}>Domine</option>
            <option value="Bree Serif" style={{ fontFamily: 'Bree Serif' }}>Bree Serif</option>
            <option value="Permanent Marker" style={{ fontFamily: 'Permanent Marker' }}>Permanent Marker</option>
            <option value="Patrick Hand" style={{ fontFamily: 'Patrick Hand' }}>Patrick Hand</option>
            <option value="Annie Use Your Telescope" style={{ fontFamily: 'Annie Use Your Telescope' }}>Annie Use Your Telescope</option>
            <option value="Cookie" style={{ fontFamily: 'Cookie' }}>Cookie</option>
            <option value="Kablammo" style={{ fontFamily: 'Kablammo' }}>Kablammo</option>
            <option value="Danfo" style={{ fontFamily: 'Danfo' }}>Danfo</option>
            <option value="Atma" style={{ fontFamily: 'Atma' }}>Atma</option>
            <option value="Caesar Dressing" style={{ fontFamily: 'Caesar Dressing' }}>Caesar Dressing</option>
            <option value="DynaPuff" style={{ fontFamily: 'DynaPuff' }}>DynaPuff</option>
            <option value="Combo" style={{ fontFamily: 'Combo' }}>Combo</option>
            <option value="Stick" style={{ fontFamily: 'Stick' }}>Stick</option>
            <option value="Creepster" style={{ fontFamily: 'Creepster' }}>Creepster</option>
            <option value="Potta One" style={{ fontFamily: 'Potta One' }}>Potta One</option>
            <option value="Courier Prime" style={{ fontFamily: 'Courier Prime' }}>Courier Prime</option>
            <option value="VT323" style={{ fontFamily: 'VT323' }}>VT323</option>
            <option value="Syne Mono" style={{ fontFamily: 'Syne Mono' }}>Syne Mono</option>
            <option value="Monofett" style={{ fontFamily: 'Monofett' }}>Monofett</option>
        </select>
    );
}

interface FontSelectorProps {
    label: string;
    initialFont?: string;
    initialFontSize?: number;
    onFontChange?: (font: string) => void;
    onFontSizeChange?: (fontSize: number) => void;
}

export const FontSelector: React.FC<FontSelectorProps> = ({ label, onFontChange, onFontSizeChange, initialFontSize = 20, initialFont = "Arial" }) => {
    const [font, setFont] = useState(initialFont);
    const [showPicker, setShowPicker] = useState(false);
    const [fontSize, setFontSize] = useState(initialFontSize);

    const changeFont = (font: string) => {
        setFont(font);
        onFontChange && onFontChange(font);
    };

    const changeFontSize = (fontSize: number) => {
        setFontSize(fontSize);
        onFontSizeChange && onFontSizeChange(fontSize);
    }

    return (
        <div className='font-selector-container'>
            <label>Font:</label>
            <FontPicker initialFont={font} onFontChange={changeFont} />
            <label>Size:</label>
            <input type='number' value={fontSize} onChange={e => changeFontSize(parseInt(e.target.value))} />
        </div>
    );
}

interface CompositeSelectorProps {
    header: string;
    initialFont?: string;
    initialFontSize?: number;
    initialTextColor?: string;
    initialBackColor?: string;
    onFontChange?: (font: string) => void;
    onFontSizeChange?: (fontSize: number) => void;
    onTextColorChange?: (color: string) => void;
    onBackColorChange?: (color: string) => void;
}

export const CompositeSelector: React.FC<CompositeSelectorProps> = ({
    header,
    initialFont,
    initialFontSize,
    initialTextColor,
    initialBackColor,
    onFontChange,
    onFontSizeChange,
    onTextColorChange: onColor1Change,
    onBackColorChange: onColor2Change
}) => {
    return (
        <div className='composite-selector-container'>
            <h1>{header}</h1>
            <FontSelector
                label="Font Selector"
                initialFont={initialFont}
                initialFontSize={initialFontSize}
                onFontChange={onFontChange}
                onFontSizeChange={onFontSizeChange}
            />
            <ColorSelector
                label="Text:"
                initialColor={initialTextColor}
                onColorChange={onColor1Change}
            />
            <ColorSelector
                label="Back:"
                initialColor={initialBackColor}
                onColorChange={onColor2Change}
            />
        </div>
    );
};