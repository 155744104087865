import React, { Component } from 'react';
import { User, UserLevel } from '../models/user';
import httpService from '../api/HttpService';
import authService from '../api/AuthorizeService';
import { AddUserModal } from './add-user-modal';
import Button from 'react-bootstrap/Button';
import { ConfirmModal } from './confirm-modal';
import { ChangePinModal } from './change-pin-modal';

interface UsersCardProps {
    organizationId: string;
}

interface UsersCardState {
    users: User[];
    showAddUserModal: boolean;
    showConfirmDelete: boolean;
    showChangePinModal: boolean;
    resetPasswordId?: string;
    deleteUser?: User;
}

export class UsersCard extends Component<UsersCardProps, UsersCardState> {

    constructor(props: UsersCardProps) {
        super(props);

        this.state = {
            users: [],
            showAddUserModal: false,
            showConfirmDelete: false,
            showChangePinModal: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    getUserLevelName(userLevel: UserLevel): string {
        switch (userLevel) {
            case UserLevel.AppLogin:
                return "App login";
            case UserLevel.LocalAdmin:
                return "Administrator";
            case UserLevel.SuperAdmin:
                return "Super admin";
        }
        return "";
    }

    renderUsersTable(users: User[]): JSX.Element {
        const userId: string = authService.getUser().id;
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Level</th>
                        {authService.isAdmin() &&
                            <th>Action</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {users.map((user: User) =>
                        <tr>
                            <td>{user.username}</td>
                            <td>{this.getUserLevelName(user.userLevel)}</td>

                            {authService.isAdmin() &&
                                <td>
                                    {userId != user.id && authService.isSuperAdmin() &&

                                        <button type="button" className="btn btn-outline-danger btn-sm mr-3" onClick={() => this.deleteClick(user)}>Delete</button>

                                    }
                                    {user.userLevel === UserLevel.AppLogin &&
                                        <button type="button" className="btn btn-outline-primary btn-sm" disabled={this.state.showChangePinModal} onClick={() => this.sendRequest(user)}>Change pin</button>
                                    }
                                </td>
                            }

                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {

        let contents = this.state.users.length > 0 ?
            this.renderUsersTable(this.state.users)
            :
            <p><em>Loading...</em></p>;

        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Users</h5>
                    {contents}
                    {authService.isSuperAdmin() &&
                        <Button variant="primary" onClick={() => this.setState({ showAddUserModal: true })}>
                            Add user
                        </Button>
                    }
                </div>
                <AddUserModal show={this.state.showAddUserModal} onClose={() => this.setState({ showAddUserModal: false })} onAddClick={this.addUser} />
                <ConfirmModal show={this.state.showConfirmDelete} message="Are you sure you wish to delete this user?" header="Delete user" onClose={this.confirmDelete} />
                <ChangePinModal show={this.state.showChangePinModal} title="Change pin" onClose={() => this.setState({ showChangePinModal: false })} onAddClick={this.changePinClick}></ChangePinModal>
            </div>
        );
    }

    private deleteClick = (user: User): void => {
        this.setState({ deleteUser: user, showConfirmDelete: true });
    }

    private changePinClick = async (pin: string): Promise<void> => {
        if (this.state.resetPasswordId) {
            let body = {
                resetId: this.state.resetPasswordId,
                password: pin,
                confirmPassword: pin
            }; 
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            };
            const response = await fetch(`${httpService.BaseUrl}api/users/change-password`, requestOptions);

            if (response.status === 201) {
                alert("Pin code changed!");
            }
            else {
                console.log(response);
                alert("Failed");
            }
        }
        else {
            alert("Pin code reset failed");
        }
        this.setState({showChangePinModal: false, resetPasswordId: undefined});
    }

    async sendRequest(user: User) {
        this.setState({
            showChangePinModal: true
        });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        console.log("Hej");
        let email = user.username;
        const response = await fetch(`${httpService.BaseUrl}api/users/reset-password/${email}`, requestOptions);

        if (response.status === 201) {
            const data = await response.json();
            console.log(data.id);
            this.setState({
                resetPasswordId: data.id
            });
        }
    }

    private confirmDelete = async (accept: boolean): Promise<void> => {
        if (accept && this.state.deleteUser) {
            await this.deleteUser(this.state.deleteUser.id);
        }
        this.setState({ deleteUser: undefined, showConfirmDelete: false });
    }

    private deleteUser = async (id: string): Promise<void> => {
        const deletedUser: User = await httpService.delete<User>('api/users', id);
        this.loadData();
    }

    private addUser = async (username: string, password: string, confirmPassword: string): Promise<void> => {
        const response = await authService.register(username, password, confirmPassword, this.props.organizationId);
        this.setState({ showAddUserModal: false });
        if (response.ok) {

            this.loadData();
        }
    }

    private loadData = async (): Promise<void> => {
        const users = await httpService.get<User[]>(`api/users?organizationId=${this.props.organizationId}`);

        this.setState({ users });
    }
}