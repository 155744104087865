import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import cuescoreService from '../api/CuescoreService';
import httpService from '../api/HttpService';
import { CS_Match, CS_Tournament } from '../models/cuescore-models';
import { LiveScore } from '../models/livescore';
import { MatchStartRequest } from '../models/match-start-request';
import { Organization } from '../models/organization';
import { CuescoreMatch } from './cuescore-match';
import { StartMatchModal } from './start-match-modal';
import { TournamentDirectorInfoDto } from '../models/tournament-director-info-dto';

interface TournamentListProps {
    organizationId: string;
    organization?: Organization;
    tournamentId: string;
    directorMode?: TournamentDirectorInfoDto;
}

const TournamentList: React.FC<TournamentListProps> = ({ organizationId, directorMode: directorInfo, organization, tournamentId }) => {
    const [tournament, setTournament] = useState<CS_Tournament | undefined>(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedMatch, setSelectedMatch] = useState<CS_Match | undefined>(undefined);
    const [liveLicenses, setLiveLicenses] = useState<LiveScore[] | undefined>(undefined);
    const [matchStartRequests, setMatchStartRequests] = useState<MatchStartRequest[] | undefined>(undefined);

    const loadMatchStartRequests = useCallback(async () => {
        const url = `api/MatchStartRequests?organizationId=${organizationId}`;
        const requests = await httpService.get<MatchStartRequest[]>(url);
        setMatchStartRequests(requests);
    }, [organizationId]);

    const loadLiveLicenses = useCallback(async () => {
        const url = `api/livescores?organizationId=${organizationId}`;
        const liveLicenses = await httpService.get<LiveScore[]>(url);
        setLiveLicenses(liveLicenses.sort((a, b) => {
            const regex = /\d+/g;
            const aNumber = a.tableName.match(regex);
            const bNumber = b.tableName.match(regex);
            if (aNumber && bNumber) {
                return parseInt(aNumber[0]) - parseInt(bNumber[0]);
            } else {
                return -1;
            }
        }));
    }, [organizationId]);

    const loadData = useCallback(async (id: number) => {
        const data = await cuescoreService.getTournament(id + "");
        setTournament(data);
        console.log(data);
    }, []);

    const refreshTournamentData = useCallback(async () => {
        if (tournament) {
            await loadData(tournament.tournamentId);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            await loadMatchStartRequests();
            await loadData(parseInt(tournamentId));
        };
        fetchData();
        const interval = setInterval(() => refreshTournamentData(), 1000 * 60 * 5); // Every 5 minutes
        return () => clearInterval(interval);
    }, [loadMatchStartRequests, loadData, refreshTournamentData, tournamentId]);

    const matchStartClicked = (match: CS_Match) => {
        console.log("Match start");
        if (match.playerA.playerId === 0 || match.playerB.playerId === 0) {
            refreshTournamentData();
        }
        if (match) {
            setShowModal(true);
            setSelectedMatch(match);
            loadLiveLicenses();
            loadMatchStartRequests();
        }
    };

    const renderMatches = (matches: CS_Match[]): JSX.Element => (
        <div>
            <h3>Matches</h3>
            <div>
                {matches.map((match) => (
                    <CuescoreMatch
                        key={match.matchId}
                        match={match}
                        matchStartRequests={matchStartRequests}
                        onClick={matchStartClicked}
                    />
                ))}
            </div>
        </div>
    );

    const startableMatches = tournament?.matches
        .filter((x) => x.matchstatusCode === 0 && x.playerA.name && x.playerB.name)
        .sort((a, b) => new Date(a.starttime).getTime() - new Date(b.starttime).getTime());

    // Tournament is over if stoptime is set and it is more than 24 hours in the past
    const tournamentOver = tournament?.stoptime && new Date(tournament.stoptime).getTime() + 1 * 24 * 60 * 60 * 1000 < new Date().getTime();

    const content = startableMatches && renderMatches(startableMatches);

    return (
        <div>
            <div>
                {tournament &&
                    <h2>
                        {tournament.name}
                    </h2>
                }
                {tournamentOver ?
                    <div>Tournament ended</div>
                    :
                    content
                }
            </div>
            {selectedMatch && (
                <StartMatchModal
                    liveLicenses={liveLicenses}
                    tournamentId={tournament?.tournamentId}
                    tournamentName={tournament?.name}
                    organizationId={organizationId}
                    matchStartRequests={matchStartRequests}
                    match={selectedMatch}
                    show={showModal}
                    onMatchStarted={loadMatchStartRequests}
                    onClose={() => setShowModal(false)}
                    directorInfo={directorInfo}
                />
            )}
        </div>
    );
};

export default TournamentList;