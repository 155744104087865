import React, { Component } from 'react';
import httpService from '../api/HttpService';
import { Organization } from '../models/organization';
import { Link } from 'react-router-dom';
import authService from '../api/AuthorizeService';

interface OrganizationInfoProps {
    organizationId: string;
}

interface OrganizationInfoState {
    organization?: Organization
}

export class OrganizationInfo extends Component<OrganizationInfoProps, OrganizationInfoState> {

    constructor(props: OrganizationInfoProps) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        if (!this.state.organization) {
            return (
                <p>Loading...</p>
            );
        }
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Organization info</h5>
                    <h5 className="card-title">{this.state.organization.name}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{this.state.organization.clubName}</h6>
                    <p className="card-text">{this.state.organization.address}<br />{this.state.organization.city}<br />{this.state.organization.country}</p>
                    {authService.isAdmin() &&
                        <Link className="btn btn-outline-primary" to={`/organization/${this.state.organization.id}`}>Edit</Link>
                    }
                </div>
            </div>
        );
    }

    private loadData = async () => {
        const url = `api/organizations/${this.props.organizationId}`;
        console.log(url);
        let org = await httpService.get<Organization>(url);

        this.setState({ organization: org });
    }
}