import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import authService from '../api/AuthorizeService';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router-dom';

interface Props { }
interface State {
    navigate: boolean;
}

export class NavMenu extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            navigate: false
        };

        authService.subscribe(() => {
            this.setState({});
        });
    }

    logout = () => {
        authService.signOut();
        this.setState({ navigate: true });
    }

    render() {

        let isAuthenticated = authService.isAuthenticated();

        if (this.state.navigate) {
            this.setState({ navigate: false });
            return <Redirect to="/" push={true} />;
        }

        let userName = '';
        let userLink = '';
        let liveLink = '/live';

        if (isAuthenticated) {
            let userlevel = "App login";
            userLink = '/dashboard';
            if (authService.isLocalAdmin()) {
                userlevel = "Admin";
            }
            if (authService.isSuperAdmin()) {
                userlevel = "Super admin";
                userLink = '/admin';
            }
            userName = `${authService.getUser().email} (${userlevel})`;
            liveLink = `/live/${authService.getUser().organizationId}`;
        }
        return (
            <header>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Navbar.Brand href="/"><img height="40" src={"/logo512.png"} /></Navbar.Brand>
                    <Navbar.Brand href="/">Scoreboard</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">

                            {isAuthenticated ?
                                <Nav className="mr-auto">
                                    <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                                </Nav>
                                :
                                <Nav className="mr-auto">
                                    <Nav.Link href="/pricing">Pricing</Nav.Link>
                                    <Nav.Link href="/about">About</Nav.Link>
                                </Nav>
                            }
                            <Nav className="mr-auto">
                                <Nav.Link href={liveLink}>Live</Nav.Link>
                            </Nav>
                            <Nav className="mr-auto">
                                <Nav.Link href="/download">Download</Nav.Link>
                            </Nav>
                            <Nav className="mr-auto">
                                <Nav.Link href="/stream-guide">Stream guide</Nav.Link>
                            </Nav>
                        </Nav>
                        {isAuthenticated &&
                            <Nav className="">
                                <Nav.Link href={userLink}>{userName}</Nav.Link>
                            </Nav>
                        }
                        {isAuthenticated ?
                            <Button onClick={() => this.logout()}>Log out</Button>
                            :
                            <Nav>
                                <Nav.Link href="/register">Register</Nav.Link>
                                <Nav.Link className="btn btn-primary text-white" href="/login">Login</Nav.Link>
                            </Nav>
                        }
                    </Navbar.Collapse>
                </Navbar>
            </header>
        );
    }
}