import React, { Component } from 'react';
import httpService from '../api/HttpService';
import { Organization } from '../models/organization';
import { Link, Redirect } from 'react-router-dom';
import { OrganizationInfo } from './organization-info';
import authService from '../api/AuthorizeService';
import { Button, Spinner } from 'react-bootstrap';
import moment from 'moment';


interface Props {

}

interface State {
    organizations: Organization[],
    waiting: boolean
}

export class Admin extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            organizations: [],
            waiting: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    renderOrganizations(organizations: Organization[]): JSX.Element {
        return (
            <table className="table">
                <tr>
                    <th>Dashboard</th>
                    <th>Livescore</th>
                    <th>Trial</th>
                    <th></th>
                    <th></th>
                </tr>
                {organizations.map((organization: Organization) =>
                    <tr>
                        <td>
                            {organization.deleted ?
                                <div>(Deleted) {organization.name}</div>
                                :
                                <Link to={`/dashboard/${organization.id}`}>{organization.name}</Link>
                            }
                        </td>
                        <td>
                            {organization.deleted ?
                                <div></div>
                                :
                                <Link to={`/live/${organization.id}`}>{organization.name}</Link>
                            }
                        </td>
                        <td>{this.renderTrialStatus(organization)}</td>
                        <td>
                            {authService.getUser().organizationId != organization.id && !organization.deleted ?
                                <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => this.deleteClick(organization)}>Delete</button>
                                :
                                <div></div>
                            }
                            {authService.getUser().organizationId != organization.id && organization.deleted ?
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.restoreClick(organization)}>Restore</button>
                                :
                                <div></div>
                            }
                        </td>
                        <td>
                            {organization.deleted &&
                                <button type="button" className="btn btn-outline-danger btn-sm">Hard delete</button>
                            }
                        </td>
                    </tr>
                )}
            </table>
        );

        return (
            <div>
                {organizations.map((organization: Organization) =>
                    <p><Link to={`/live/${organization.id}`}>{organization.name}</Link></p>
                )}
            </div>
        );
    }

    renderTrialStatus(org: Organization): JSX.Element {
        if (org?.paymentStatus?.startsWith("trial")) {
            if (org?.paymentStatus?.includes("awaiting")) {
                return (
                    <div className='d-flex'>
                        <button type="button" className="btn btn-outline-success btn-sm mx-2" onClick={() => this.acceptTrial(org)}>Accept</button>
                        <button type="button" className="btn btn-outline-dark btn-sm" onClick={() => this.declineTrial(org)}>Decline</button>
                    </div>
                );
            }
            else {
                let expireDate = moment(org.validUntil);
                let today = moment();
                let diff = expireDate.diff(today, 'days') + 1;
                return (
                    <div><p>Trial expires in {diff} days</p></div>
                );
            }
        }
        return (<div></div>);
    }

    render() {
        if (!authService.isSuperAdmin()) {
            return (<Redirect to="/" />);
        }
        let contents = <p><em>Loading...</em></p>;

        if (this.state.organizations) {
            contents = this.renderOrganizations(this.state.organizations);
        }
        return (
            <div>
                <div className="header">
                    <h1>Admin dashboard</h1>
                </div>
                <h2>Organizations</h2>
                <p><Link to={`/register`}>Create organization</Link></p>
                {contents}
            </div>
        );
    }

    private deleteClick = async (org: Organization) => {
        const deletedOrg = await httpService.delete<Organization>("api/organizations", org.id);
        await this.loadData();
    }

    private acceptTrial = async (org: Organization) => {
        const response = await httpService.postWithId("api/organizations/accept-trial", org.id, null);
        await this.loadData();
    }

    private declineTrial = async (org: Organization) => {

    }

    private restoreClick = async (org: Organization) => {
        org.deleted = false;
        await httpService.put<Organization>("api/organizations", org.id, org);
        await this.loadData();
    }

    private loadData = async (): Promise<void> => {
        const organizations = await httpService.get<Organization[]>("api/organizations");

        this.setState({
            organizations: organizations
        });
    }
}