import React, { ChangeEvent, Component } from "react"
import { Col, Form, FormControl, Row } from "react-bootstrap"
import { PriceElement } from "./price-element";

interface Props {
    editable: boolean;
    selectable?: boolean;
    onUpdate?: (numberOfTables?: number, selectedPlan?: string, currency?: string, billingPeriod?: string, averagePrice?: number) => void;    
}

interface State {
    numberOfTables: number;
    selectedPlan?: string;
    currency?: string;
    billingPeriod?: string;
}

export class PriceTable extends Component<Props, State>  {

    private hobbyPrice: number = 5;
    private clubPrice: number = 12;
    private venuePrice: number = 20;

    constructor(props: Props) {
        super(props);

        this.state = {
            numberOfTables: 2,
            currency: "euro",
            billingPeriod: "month"
        };
    }

    private calculateAveragePrice = (numberOfTables: number): number => {
        let discountedTables = 0;
        let discountedPrice = 1;
        for (var i = 0; i < this.state.numberOfTables; i++) {
            if (i > 1) {
                discountedPrice = 0.8;
            }
            if (i > 3) {
                discountedPrice = 0.6;
            }
            if (i > 5) {
                discountedPrice = 0.5;
            }
            discountedTables += discountedPrice;
        }

        return discountedTables / numberOfTables;
    }

    private onCurrencyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({currency: event.target.value}, this.onUpdate);
    }

    private onBillingPeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({billingPeriod: event.target.value}, this.onUpdate);
    }

    private planSelected = (plan: string) => {
        this.setState({ selectedPlan: plan }, this.onUpdate);
    }

    private changeNumberOfTables = (change: number) =>  {
        this.setState({numberOfTables: Math.max(1, this.state.numberOfTables + change)}, this.onUpdate);
    }

    private onUpdate = () => {
        let averagePrice: number | undefined;
        if (this.state.selectedPlan && this.state.numberOfTables) {
            switch (this.state.selectedPlan) {
                case "Hobby":
                    averagePrice = this.calculateAveragePrice(this.state.numberOfTables) * this.hobbyPrice;
                    break;
                case "Club":
                    averagePrice = this.calculateAveragePrice(this.state.numberOfTables) * this.clubPrice;
                    break;
                case "Venue":
                    averagePrice = this.calculateAveragePrice(this.state.numberOfTables) * this.venuePrice;
                    break;
            }
        }
        console.log("Average price: " + averagePrice, this.state);
        this.props.onUpdate && this.props.onUpdate(this.state.numberOfTables, this.state.selectedPlan, this.state.currency, this.state.billingPeriod, averagePrice);
    }

    render() {

        const hobbyFeatures: string[] = ["Scoreboard app"];
        const clubFeatures: string[] = hobbyFeatures.concat(["Livescore", "Stream overlay", "Tournament integration"]);
        const profFeatures: string[] = clubFeatures.concat(["Custom design", "Customizable ads", "Your own logo"]);

        return (
            <div>
                <div className="d-flex justify-content-center mt-4">
                    <div>
                        <h4 style={{textAlign: "center"}}>How many tables do you have?</h4>
                        <div className="d-flex justify-content-center mt-4">
                            <button type="button" disabled={!this.props.editable} className="btn btn-primary big-number" onClick={() => this.changeNumberOfTables(-1)}><span className=" big-number">-</span></button>
                            <h3 className="mx-5">{this.state.numberOfTables}</h3>

                            <button type="button" disabled={!this.props.editable} className="btn btn-primary" onClick={() => this.changeNumberOfTables(1)}><span className=" big-number">+</span></button>
                        </div>
                        <div className="mt-4 d-flex justify-content-center">
                            <span className="currency-label">Currency</span> 
                            <div className="form-group">
                                <select className="custom-select" value={this.state.currency} onChange={this.onCurrencyChange.bind(this)}>
                                    <option key="euro" value="euro">Euro (€)</option>
                                    <option key="kr." value="dkk">DKK (kr.)</option>
                                </select>
                            </div>
                            <span className="currency-label ml-4">Billing period:</span> 
                            <div className="form-group">
                                <select className="custom-select" value={this.state.billingPeriod} onChange={this.onBillingPeriodChange.bind(this)}>
                                    <option key="month" value="month">per month</option>
                                    <option key="year" value="year">per year</option>
                                </select>
                            </div>
                        </div>
                        <div className="mb-2 d-flex justify-content-center">
                            You get 2 months for free if you pay per year instead of per month
                        </div>
                    </div>
                </div>
                {this.props.selectable ?
                    <Row>
                        <Col md={4}>
                            <PriceElement planName="Hobby" currency={this.state.currency} billedYearly={this.state.billingPeriod === "year"} selectedPlan={this.state.selectedPlan} tablePrice={this.calculateAveragePrice(this.state.numberOfTables)} basePrice={this.hobbyPrice} numberOfTables={this.state.numberOfTables} features={hobbyFeatures} onSelected={() => this.planSelected("Hobby")}></PriceElement>
                        </Col>
                        <Col md={4}>
                            <PriceElement planName="Club" currency={this.state.currency} billedYearly={this.state.billingPeriod === "year"} selectedPlan={this.state.selectedPlan} tablePrice={this.calculateAveragePrice(this.state.numberOfTables)} basePrice={this.clubPrice} numberOfTables={this.state.numberOfTables} features={clubFeatures} onSelected={() => this.planSelected("Club")}></PriceElement>
                        </Col>
                        <Col md={4}>
                            <PriceElement planName="Venue" currency={this.state.currency} billedYearly={this.state.billingPeriod === "year"} selectedPlan={this.state.selectedPlan} tablePrice={this.calculateAveragePrice(this.state.numberOfTables)} basePrice={this.venuePrice} numberOfTables={this.state.numberOfTables} features={profFeatures} onSelected={() => this.planSelected("Venue")}></PriceElement>
                        </Col>
                    </Row>
                    :
                    <Row> 
                        <Col md={4}>
                            <PriceElement planName="Hobby" billedYearly={this.state.billingPeriod === "year"} currency={this.state.currency} tablePrice={this.calculateAveragePrice(this.state.numberOfTables)} basePrice={this.hobbyPrice} numberOfTables={this.state.numberOfTables} features={hobbyFeatures} ></PriceElement>
                        </Col>
                        <Col md={4}>
                            <PriceElement planName="Club" billedYearly={this.state.billingPeriod === "year"} currency={this.state.currency} tablePrice={this.calculateAveragePrice(this.state.numberOfTables)} basePrice={this.clubPrice} numberOfTables={this.state.numberOfTables} features={clubFeatures} ></PriceElement>
                        </Col>
                        <Col md={4}>
                            <PriceElement planName="Venue" billedYearly={this.state.billingPeriod === "year"} currency={this.state.currency} tablePrice={this.calculateAveragePrice(this.state.numberOfTables)} basePrice={this.venuePrice} numberOfTables={this.state.numberOfTables} features={profFeatures} ></PriceElement>
                        </Col>
                    </Row>
                }
            </div>
        )
    }
}