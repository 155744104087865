import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import TournamentList from './tournament-list';
import { TournamentDirectorInfoDto } from '../models/tournament-director-info-dto';
import httpService from '../api/HttpService';
import { OrganizationPublicDTO } from '../models/organization-public-dto';

const TournamentDirector: React.FC = () => {
    const { directorId } = useParams<{ directorId: string }>();
    const [tdo, setTdo] = useState<TournamentDirectorInfoDto | null>(null);
    const [publicDto, setPublicDto] = useState<OrganizationPublicDTO | null>(null);

    useEffect(() => {
        console.log(directorId);

        const fetchData = async () => {
            const response = await httpService.get<TournamentDirectorInfoDto>(`api/tournamentDirectorInfos/${directorId}`);
            setTdo(response);
        };

        fetchData();
    }, [directorId]);

    useEffect(() => {
        console.log(tdo);

        const fetchOrg = async () => {
            const organization = await httpService.anonGet<OrganizationPublicDTO>(`api/organizations/all/${tdo?.organizationId}`);
            setPublicDto(organization);
        };

        tdo && tdo.organizationId && fetchOrg();
    }, [tdo]);

    return (
        <div>
            <div className="header">
                <h1>Tournament director</h1>
            </div>
            {publicDto &&
                <div>
                    <h2>{publicDto.name}</h2>
                </div>
            }
            {tdo &&
                <div>
                    <TournamentList organizationId={tdo.organizationId} directorMode={tdo} tournamentId={tdo.tournamentId} />
                </div>}
        </div>
    );
};

export default TournamentDirector;