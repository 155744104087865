import moment from "moment";
import React from "react";
import { Component } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import httpService from "../api/HttpService";
import { CS_Match } from "../models/cuescore-models";
import { LicenseEntity } from "../models/license-entity";
import { LiveScore } from "../models/livescore";
import { MatchStartRequest } from "../models/match-start-request";
import { TournamentDirectorInfoDto } from "../models/tournament-director-info-dto";

interface Props {
    liveLicenses?: LiveScore[];
    onMatchStarted?: () => void;
    onClose?: () => void;
    show: boolean;
    match?: CS_Match;
    matchStartRequests?: MatchStartRequest[];
    tournamentName?: string;
    organizationId?: string;
    tournamentId?: number;
    directorInfo?: TournamentDirectorInfoDto;
}

interface State {
    isReady: boolean;
    showModal: boolean;
    showRenameModal: boolean;
    showConfirmDelete: boolean;
    showConfirmRevoke: boolean;
    editLicense?: LicenseEntity;
    selectedLivescore?: LiveScore;
    waiting: boolean;
}

export class StartMatchModal extends Component<Props, State> {
    private _apiPath: string = 'api/MatchStartRequests';

    constructor(props: Props) {
        super(props);

        this.state = {
            isReady: false,
            showModal: false,
            showRenameModal: false,
            showConfirmDelete: false,
            showConfirmRevoke: false,
            waiting: false
        };
    }

    private chooseClick = (livescore: LiveScore) => {
        this.setState({ selectedLivescore: livescore });
    }

    private closeClick = () => {
        this.setState({ selectedLivescore: undefined });
        this.props.onClose && this.props.onClose();
    }

    private startMatchClick = async () => {
        // callback
        // 
        this.setState({ waiting: true });
        if (this.state.selectedLivescore && this.props.match) {
            const matchStartRequest: MatchStartRequest = {
                licenseEntityId: this.state.selectedLivescore.licenseEntityId,
                player1Id: this.props.match.playerA.playerId.toString(),
                player1FirstName: this.props.match.playerA.firstname,
                player1LastName: this.props.match.playerA.lastname,
                player1ImageUrl: this.props.match.playerA.image,
                player1CountryImageUrl: this.props.match.playerA.country.image,
                player2Id: this.props.match.playerB.playerId.toString(),
                player2FirstName: this.props.match.playerB.firstname,
                player2LastName: this.props.match.playerB.lastname,
                player2ImageUrl: this.props.match.playerB.image,
                player2CountryImageUrl: this.props.match.playerB.country.image,
                raceTo: this.props.match.raceTo,
                bestOf: this.props.match.bestOfSets,
                matchId: this.props.match.matchId.toString(),
                matchNo: this.props.match.matchno.toString(),
                roundName: this.props.match.roundName,
                startTime: this.props.match.starttime,
                tournamentName: this.props.tournamentName,
                organizationId: this.props.organizationId,
                tournamentId: this.props.tournamentId?.toString()
            };

            let response = await (this.props.directorInfo ?
                httpService.postWithId<MatchStartRequest>(this._apiPath, this.props.directorInfo?.token ?? "?", matchStartRequest) :
                httpService.post<MatchStartRequest>(this._apiPath, matchStartRequest));

        }
        this.setState({ selectedLivescore: undefined, waiting: false });

        this.props.onMatchStarted && this.props.onMatchStarted();
        this.props.onClose && this.props.onClose();
    }

    private renderTable = (live: LiveScore) => {
        const isStarted = this.props.matchStartRequests && this.props.matchStartRequests.some(x => x.licenseEntityId === live.licenseEntityId);
        const matchFinished = live.distance > 0 && (live.player1FramesWon == Math.ceil(live.distance / 2.0) || live.player2FramesWon == Math.ceil(live.distance / 2.0));
        const selected = this.state.selectedLivescore && this.state.selectedLivescore.licenseEntityId === live.licenseEntityId;
        let buttonVariant: "primary" | "success" | "outline-primary" | "outline-success" = "primary";
        if (matchFinished) {
            if (selected) {
                buttonVariant = "success";
            }
            else {
                buttonVariant = "outline-success";
            }
        }
        else {
            if (selected) {
                buttonVariant = "primary";
            }
            else {
                buttonVariant = "outline-primary";
            }
        }

        let content = "Choose";
        if (selected) {
            content = "Selected";
        }
        if (isStarted) {
            content = "Pending";
        }

        const playerMissing = !this.props.match || this.props.match.playerA.playerId == 0 || this.props.match.playerB.playerId == 0;

        console.log("Players", this.props.match?.playerA.playerId, this.props.match?.playerB.playerId, playerMissing);

        return (
            <div className="d-flex justify-content-between cs-match">
                <div>
                    <div><b>{live.tableName}</b> (latest activity {moment(live.updatedAt).fromNow()})</div>
                    <div className="current-match">Current match:</div>
                    <div>{live.player1FirstName} {live.player1LastName} <b>{live.player1Score} ({live.player1FramesWon})</b> vs. <b>({live.player2FramesWon}) {live.player2Score}</b> {live.player2FirstName} {live.player2LastName} </div>
                </div>
                <div>
                    <Button variant={buttonVariant} disabled={isStarted || playerMissing} onClick={() => this.chooseClick(live)}>
                        {content}
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        if (!this.props.show) {
            return (<div></div>);
        }
        const playerMissing = !this.props.match || this.props.match.playerA.playerId == 0 || this.props.match.playerB.playerId == 0;
        return (
            <Modal show={this.props.show} onHide={this.closeClick}>
                <Modal.Header closeButton>
                    <Modal.Title>Start match</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{this.props.match?.playerA.name} vs. {this.props.match?.playerB.name}</h4>
                    {this.state.selectedLivescore ?
                        <>
                            {this.renderTable(this.state.selectedLivescore)}
                            <br></br>
                            <h4 className="mr-auto">Are you sure you want to start the match on <b>{this.state.selectedLivescore.tableName}?</b></h4>
                        </>

                        :
                        <>
                            <div className="my-4">Choose table:</div>
                            <div>
                                {this.props.liveLicenses?.map(x => this.renderTable(x))}
                            </div>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {this.state.selectedLivescore &&
                        <Button variant="primary" disabled={!this.state.selectedLivescore || this.state.waiting || playerMissing} onClick={this.startMatchClick}>
                            {this.state.waiting && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            }
                            <span className="mx-2">Start match</span>
                        </Button>
                    }
                    <Button variant="secondary" onClick={this.closeClick}>
                        Cancel
                    </Button>


                </Modal.Footer>
            </Modal>
        )
    }
}