import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PriceCalculator } from './price-calculator';
import { PriceTable } from './price-table';
import { Title } from './title';


interface Props {

}

interface State {
    organizationId: string;
}

export class Pricing extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

    }

    renderPriceTier(tier: any): JSX.Element {
        return (
            <div className="card mb-5 mb-lg-0">
                <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">{tier.name}</h5>
                    <h6 className="card-price text-center">{tier.price}<span className="period">/table</span></h6>

                    {/* <ul className="fa-ul">
                        <li><span className="fa-li"><i className="fas fa-check"></i></span>Single User</li>
                        <li><span className="fa-li"><i className="fas fa-check"></i></span>5GB Storage</li>
                        <li><span className="fa-li"><i className="fas fa-check"></i></span>Unlimited Public Projects</li>
                        <li><span className="fa-li"><i className="fas fa-check"></i></span>Community Access</li>
                        <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Unlimited Private Projects</li>
                        <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Dedicated Phone Support</li>
                        <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Free Subdomain</li>
                        <li className="text-muted"><span className="fa-li"><i className="fas fa-times"></i></span>Monthly Status Reports</li>
                    </ul> */}
                    <a href="#" className="btn btn-block btn-primary text-uppercase">Select</a>
                </div>
            </div>
        );
    }

    renderPriceTiers(priceTiers: any[]): JSX.Element {
        return (
            <section className="pricing py-5">
                <div className="container">
                    <div className="row">
                        {priceTiers.map((tier) =>
                            <div className="col-lg-4">

                                {this.renderPriceTier(tier)}
                            </div>

                        )}
                    </div>
                </div>
            </section>
        );
    }

    renderPriceTable(): JSX.Element {
        let priceTier = {
            name: "Hobby",
            price: "€5"
        };
        let hobby = this.renderPriceTier(priceTier);
        priceTier = {
            name: "Club",
            price: "€8"
        };
        let club = this.renderPriceTier(priceTier);
        priceTier = {
            name: "Professional",
            price: "€15"
        };
        let professional = this.renderPriceTier(priceTier);

        return (
            <table className="table table-bordered table-hover">
                <tr>
                    <td></td>
                    <td> <section className="pricing">{hobby}</section></td>
                    <td><section className="pricing">{club}</section></td>
                    <td><section className="pricing">{professional}</section></td>
                </tr>
                <tr>
                    <td>Tables</td>
                    <td>Up to 2</td>
                    <td>Unlimited</td>
                    <td>Unlimited</td>
                </tr>
                <tr>
                    <td>Scoreboard</td>
                    <td>Yes</td>
                    <td>Yes</td>
                    <td>Yes</td>
                </tr>
                <tr>
                    <td>Livescore</td>
                    <td>No</td>
                    <td>Yes</td>
                    <td>Yes</td>
                </tr>
                <tr>
                    <td>Customizable</td>
                    <td>No</td>
                    <td>No</td>
                    <td>Yes</td>
                </tr>
                <tr>
                    <td>Advertisements</td>
                    <td>Fixed</td>
                    <td>Optional</td>
                    <td>Custom</td>
                </tr>
            </table>
        );
    }

    private onPriceUpdate = (numberOfTables?: number, selectedPlan?: string, currency?: string) => {
        console.log("Tables: " + numberOfTables + ", plan: " + selectedPlan + ", currency: " + currency);
    }

    render() {
        let priceTiers = [];
        priceTiers.push({
            name: "Hobby",
            price: "€5"
        });
        priceTiers.push({
            name: "Club",
            price: "€8"
        });
        priceTiers.push({
            name: "Professional",
            price: "€15"
        });

        let tierSection = this.renderPriceTiers(priceTiers);
        let priceTable = this.renderPriceTable();
        // priceTable =  <p>Contact <a href="mailto:danieljallov@gmail.com">danieljallov@gmail.com</a> to learn more!</p>;
        return (
            <div>
                <Title text="Plans and Pricing" />
           
                <PriceTable editable={true} selectable={false} onUpdate={this.onPriceUpdate}></PriceTable>
                {/* <h4>Contact us at <a href="mailto:info@147board.com">info@147board.com</a> to learn more about pricing.</h4> */}
            </div>
        );
    }
}