import  React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


interface Props {
    onAddClick?: (name: string) => void;
    onClose?: () => void;
    show: boolean;
    title: string;
    content?: string;
}

export const AddLicenseModal = (props: Props) => {
    const p = props;
    let [input, setInput] = useState('');
   
    return (
        <div>
            {
                props.show ?
                    <Modal show={props.show} onHide={props.onClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{props.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <input type="text" className="form-control" placeholder="License name" aria-label="License name" aria-describedby="basic-addon1" value={input} onChange={e => setInput(e.target.value)}/> 
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary"  onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" disabled={input.length == 0} onClick={() => props.onAddClick && props.onAddClick(input)}> 
                                Create
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    :
                    <div></div>
            }
        </div>

    );
};

