import React from 'react';
import { Button } from 'react-bootstrap';
import { CS_Match } from '../models/cuescore-models';
import { MatchStartRequest } from '../models/match-start-request';

interface Props {
    match: CS_Match;
    matchStartRequests?: MatchStartRequest[];
    onClick: (match: CS_Match) => void;
}

export const CuescoreMatch = (props: Props) => {
    const match = props.match;

    var date = new Date(match.starttime);

    var options = { hour: '2-digit', minute: '2-digit' };
    var time = date && match.starttime ? date.toLocaleString('en-UK', options) : "?";

    var dayOptions = {weekday: 'short'};
    var day = date && match.starttime ? date.toLocaleString('en-UK', dayOptions) : "?";

    let isStarted = props.matchStartRequests && props.matchStartRequests.some(x => parseInt(x.matchId) === props.match.matchId);

    return (
        <div className="d-flex flex-row cs-match">
            <div className="cs-match-no">{match.matchno}</div>  
            <div className="cs-match-time">
                <div>
                    {day}
                </div>
                <div>
                    {time}
                </div>
            </div>
           <div className="cs-match-players">  {match.playerA.name}  &nbsp; <b>vs</b>  &nbsp; {match.playerB.name}</div>
           <div><Button variant="outline-dark" disabled={isStarted} onClick={() => props.onClick(match)}>{isStarted ? "Pending" : "Start match"}</Button></div>
        </div>
    );
}