import React from 'react';
import { LiveScore } from '../models/livescore';
import { LivescoreService } from '../api/LivescoreService';
import { useState } from 'react';
import { useEffect } from 'react';
import { LivescoreLayout } from '../models/livescore-layout';
import httpService from '../api/HttpService';

interface Props {
    // urlSearch: string;
    tableId: string;
    shorten?: boolean;
    upperCase?: boolean;
    delay?: number;
    livescoreLayout?: LivescoreLayout;
}

export const initialLivescoreLayout: LivescoreLayout = {
    playerBgColorHex: "#FFF",
    playerTextColorHex: "#000",
    playerTextSize: 22,
    playerTextFont: "Calibri",
    playerTextWeight: "normal",

    playerScoreBgColorHex: "#FFF",
    playerScoreTextColorHex: "#000",
    playerScoreTextSize: 30,
    playerScoreTextFont: "Calibri",
    playerScoreTextWeight: "normal",

    frameBgColorHex: "#2b66f2",
    frameTextColorHex: "#FFF",
    frameTextSize: 30,
    frameTextFont: "Calibri",
    frameTextWeight: "normal",
    selectedPlayerColorHex: "#2b66f2",

    id: '',
    createdAt: "",
    updatedAt: "",
    deleted: false
};

export const ShowStream = (props: Props) => {
    const { livescoreLayout: _livescoreLayout, shorten, upperCase, tableId, delay } = props;
    let [liveScore, setLiveScore] = useState<LiveScore>();
    const [livescoreLayout, setLivescoreLayout] = useState<LivescoreLayout>(initialLivescoreLayout);

    useEffect(() => {
        livescoreService.start();
        return function cleanup() {
            livescoreService.stop();
        }
    }, []);

    useEffect(() => {
        const loadLivescoreLayout = async () => {
            try {
                const response = await httpService.get<LivescoreLayout>(`api/livescoreLayouts?licenseEntityId=${tableId}`);
                if (response) {
                    setLivescoreLayout(response);
                }
            } catch (error) {

            }
        };


        if (_livescoreLayout) {
            setLivescoreLayout(_livescoreLayout);
        }
        else {
            loadLivescoreLayout();
        }
    }, [_livescoreLayout, tableId]);

    const callbackFunction = (livescore: LiveScore): void => {
        setLiveScore(livescore);
    }

    const livescoreService = new LivescoreService(tableId, 2, delay ?? 0, callbackFunction);

    const renderBallsInBreakView = (balls: number[], b: number): JSX.Element => {
        return (
            <div className="balls-container">
                <div className="mr-2">Break: {b}</div>
                {balls.map((ball: number) => {
                    const classes: string = `ball ball-${ball}`;
                    return (
                        <div className={classes}></div>
                    );
                }
                )}
            </div>
        );
    }

    const renderScore = (liveScore: LiveScore, shorten?: boolean, upperCase?: boolean): JSX.Element => {
        const player1ActiveClass = liveScore.player1IsActive ? "custom-livescore-active" : "custom-livescore-inactive";
        const player2ActiveClass = liveScore.player2IsActive ? "custom-livescore-active" : "custom-livescore-inactive";
        const player1Score = liveScore.player1Score + liveScore.player1Break;
        const player2Score = liveScore.player2Score + liveScore.player2Break;

        let ballsInBreak: JSX.Element = <div></div>;
        const player1BallsInBreak: number[] = JSON.parse(liveScore.player1BallsInBreak);
        const player2BallsInBreak: number[] = JSON.parse(liveScore.player2BallsInBreak);

        if (player1BallsInBreak.length > 0) {
            ballsInBreak = renderBallsInBreakView(player1BallsInBreak, liveScore.player1Break);
        }
        if (player2BallsInBreak.length > 0) {
            ballsInBreak = renderBallsInBreakView(player2BallsInBreak, liveScore.player2Break);
        }

        let player1FirstName = liveScore.player1FirstName;
        if (shorten && player1FirstName) {
            player1FirstName = liveScore.player1FirstName[0] + ".";
        }
        let player2FirstName = liveScore.player2FirstName;
        if (shorten && player2FirstName) {
            player2FirstName = liveScore.player2FirstName[0] + ".";
        }

        const playerStyle: React.CSSProperties = livescoreLayout ? {
            backgroundColor: livescoreLayout?.playerBgColorHex,
            color: livescoreLayout?.playerTextColorHex,
            fontFamily: livescoreLayout?.playerTextFont,
            fontSize: livescoreLayout?.playerTextSize
        } : {};

        const frameStyle: React.CSSProperties = livescoreLayout ? {
            backgroundColor: livescoreLayout?.frameBgColorHex,
            color: livescoreLayout?.frameTextColorHex,
            fontFamily: livescoreLayout?.frameTextFont,
            fontSize: livescoreLayout?.frameTextSize
        } : {};

        const scoreStyle: React.CSSProperties = livescoreLayout ? {
            backgroundColor: livescoreLayout?.playerScoreBgColorHex,
            color: livescoreLayout?.playerScoreTextColorHex,
            fontFamily: livescoreLayout?.playerScoreTextFont,
            fontSize: livescoreLayout?.playerScoreTextSize
        } : {};

        const selectedPlayerStyle: React.CSSProperties = livescoreLayout ? {
            backgroundColor: livescoreLayout?.selectedPlayerColorHex
        } : {};

        const containerClassname = "custom-livescore-container" + (upperCase ? " upper-case" : "");
        return (
            <div>
                <div className="d-flex flex-row justify-content-center">
                    <div className={containerClassname} style={frameStyle}>
                        <div className="custom-livescore-player" style={playerStyle}>
                            <div className="custom-livescore-playername">
                                <div className={player1ActiveClass} style={selectedPlayerStyle}></div>
                                <div>{player1FirstName} {liveScore.player1LastName}</div>
                            </div>
                            <div></div>
                        </div>
                        <div className="custom-livescore-score" style={scoreStyle}>{player1Score}</div>
                        <div className="custom-livescore-framescore">
                            <div className="custom-livescore-player-frames">{liveScore.player1FramesWon}</div>
                            <div className="custom-livescore-distance">({liveScore.distance})</div>
                            <div className="custom-livescore-player-frames">{liveScore.player2FramesWon}</div>
                        </div>
                        <div className="custom-livescore-score" style={scoreStyle}>{player2Score}</div>
                        <div className="custom-livescore-player" style={playerStyle}>
                            <div></div>
                            <div className="custom-livescore-playername">
                                <div>{player2FirstName} {liveScore.player2LastName}</div>
                                <div className={player2ActiveClass} style={selectedPlayerStyle}></div>
                            </div>

                        </div>
                    </div>
                </div>
                {ballsInBreak}
                <div className="d-flex flex-row justify-content-center ">
                    <div className="d-flex flex-row justify-content-center custom-powered-label">Livescore powered by 147board.com</div>
                </div>

            </div>
        );
    }

    if (liveScore) {
        return renderScore(liveScore, shorten, upperCase);
    }
    else {
        return (<div>No</div>);
    }
}