import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'
import { NavMenu } from './nav-menu';
import { Login } from './login';
import { Register } from './register';
import { Footer } from './footer';

export class Layout extends Component {
    render() {
        return (
            <div className="layout-container">
                <NavMenu></NavMenu>
                <div className="flex-grow-1">
                    <Container>
                        {this.props.children}
                    </Container>
                </div>
                <Footer></Footer>
            </div>
        );
    }
}