import React, { useCallback, useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import { Organization } from '../models/organization';
import { TournamentDirectorInfoDto } from '../models/tournament-director-info-dto';
import httpService from '../api/HttpService';
import { TournamentDirectorToken } from '../models/tournament-director-token';
import cuescoreService from '../api/CuescoreService';
import TournamentLinkEmailModal from './tournament-link-email-modal';

interface TournamentAdminProps {
    organization?: Organization;
    tournamentId?: string;
    fetchClick?: (tournamentId?: string) => void;
}

const TournamentAdmin: React.FC<TournamentAdminProps> = ({ tournamentId: initialTournamentId, fetchClick, organization }) => {
    const [tournamentId, setTournamentId] = useState<string | undefined>(initialTournamentId);
    const [token, setToken] = useState<string | undefined>();
    const [emailModalVisible, setEmailModalVisible] = useState<boolean>(false);

    const generateLink = async (tId?: string) => {
        // Define the logic for generateLink here
        console.log("Generate link clicked");
        if (organization && tId) {
            const data = await cuescoreService.getTournament(tId + "");
            if (data) {
                const tdo: TournamentDirectorInfoDto = { organizationId: organization.id, tournamentId: tId, startDate: data.starttime, endDate: data.stoptime };
                const tokenResponse = await httpService.postWithReturn<TournamentDirectorInfoDto, TournamentDirectorToken>("api/tournamentDirectorInfos", tdo);
                setToken(tokenResponse.token);
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTournamentId(event.target.value);
    };

    const fetchTournamentClick = () => {
        // Add your fetch logic here
        fetchClick?.(tournamentId);
        setToken(undefined);
    };

    const generateTournamentDirectorLink = () => {
        // Add your link generation logic here
        generateLink(tournamentId);
    };

    const sendEmails = async (emails: string[]) : Promise<boolean> => {
        const response = await httpService.postWithReturn<string[], TournamentDirectorInfoDto>(`api/tournamentDirectorInfos/send/${token}`,  emails ); 
        
        return true;     
    };

    const linkUrl = token && `${httpService.getBaseUrlForLink()}td/${token}`;

    return (
        <div className='tournament-admin-container'>
            <h1>Tournament Admin</h1>
            <p>Manage your tournament settings here.</p>
            <Form>
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Group controlId="formBasicEmail">
                            <h4>Cuescore tournament ID</h4>
                            <Form.Control
                                type="number"
                                name="username"
                                placeholder="Tournament ID"
                                value={tournamentId}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={fetchTournamentClick}>
                            Fetch tournament
                        </Button>
                    </Col>
                    <Col xs={12} md={8}>
                        <div className=''>
                            <div className=''>
                                <h4>Tournament director</h4>
                                <p>Allow the tournament director to start matches via a link, without having to log in.</p>
                                {token ?
                                    <>
                                        <p><a href={linkUrl} target='blank'>{linkUrl}</a></p>
                                        <Button variant="primary" onClick={ () => setEmailModalVisible(true) }>
                                            Send link via email
                                        </Button>
                                    </>
                                    :
                                    <Button variant="primary" onClick={generateTournamentDirectorLink}>
                                        Show tournament director link
                                    </Button>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Form>
            <TournamentLinkEmailModal visible={emailModalVisible} onClose={() => { setEmailModalVisible(false) }} onSubmit={sendEmails} />
        </div>
    );
};

export default TournamentAdmin;