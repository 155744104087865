import moment from "moment";
import React from "react";
import { Component } from "react";
import { Button } from "react-bootstrap";
import authService from "../api/AuthorizeService";
import httpService from "../api/HttpService";
import { Organization } from "../models/organization";

interface Props {
    organizationId: string;
    onUpgradeClick?: () => void;    
}

interface State {
    organization?: Organization;
}


export class TrialBanner extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {};
    }


    componentDidMount() {
        this.loadOrganization();
    }

    private loadOrganization = async () => {
        const url = `api/organizations/${this.props.organizationId}`;
        console.log(url);
        let org = await httpService.get<Organization>(url);

        this.setState({ organization: org });
    }

    private manageBilling = async () => {
        this.props.onUpgradeClick && this.props.onUpgradeClick();    
    }

    render() {
        let expireDate = moment(this.state.organization?.validUntil);
        let today = moment();
        let diff = expireDate.diff(today, 'days') + 1;
        const awaiting = this.state.organization?.paymentStatus?.includes("awaiting");
        return (
            <div>
                {this.state.organization?.paymentStatus?.startsWith("trial") &&
                    <div className="mt-4 alert alert-warning fade show">
                        {awaiting &&
                            <span className="mr-4"><strong>Trial request pending.</strong> Click here to upgrade to paid version </span>
                        }
                        {!awaiting &&
                            <span className="mr-4"><strong>Trial expires in {diff} days.</strong> Click here to upgrade to paid version </span>
                        }
                        <Button variant="secondary" onClick={this.manageBilling}>Upgrade</Button>
                    </div>
                }
            </div>
        )
    }
}