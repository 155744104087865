import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


interface Props {    
    onClose?: (accept: boolean) => void;
    message?: string;
    header?: string;
    show: boolean;
}

export const ConfirmModal = (props: Props) => {

    return (
        <div>
            {
                props.show ?
                    <Modal show={props.show} onHide={props.onClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>{props.header}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {props.message}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => props.onClose && props.onClose(false)}>
                                No
                        </Button>
                            <Button variant="primary" onClick={() => props.onClose && props.onClose(true)}>
                                Yes
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    :
                    <div></div>
            }
        </div>
    );
};