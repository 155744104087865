import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import httpService from "../api/HttpService";

interface MatchParams {
    email: string;
}

interface Props extends RouteComponentProps<MatchParams> {

}

interface State {
    email?: string;
 }

export class ResetPassword extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            email: this.props.match.params.email
        };
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    async handleClick() {
      
       await this.sendRequest();
    }

    async sendRequest() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        if (this.state.email) {
            console.log("Hej");
            let email = this.state.email;
            const response = await fetch(`${httpService.BaseUrl}api/users/reset-password/${email}`, requestOptions);

            if (response.status === 201) {
                alert("A password reset link has been sent to your email. Please check your mail and spam folder. The link is valid for 2 hours.");
            }
        }
    }

    render() {
        return (
            <div>
               <div className="header">
                    <h1>Reset password</h1>
                </div>
                <p>Enter your email below:</p>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Enter email" value={this.state.email} onChange={this.handleChange}/>                        
                    </Form.Group>                  
                    <Button variant="dark" onClick={this.handleClick}>
                        Reset password
                    </Button>                  
                </Form>
            </div>
        );
    }
}
