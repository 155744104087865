import React, { Component } from "react";
import { Button, Form } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import httpService from "../api/HttpService";

interface MatchParams {
    resetId: string;
}

interface Props extends RouteComponentProps<MatchParams> {

}

interface State {
    password?: string;
    confirmPassword?: string;
}

export class ChangePassword extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.state = {};
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value });
    }

    async handleClick() {
        await this.sendRequest();
    }

    async sendRequest() {
        
        if (this.state.password && this.state.confirmPassword) {
            let body = {
                resetId: this.props.match.params.resetId,
                password: this.state.password,
                confirmPassword: this.state.confirmPassword
            };
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(body)
            };
            const response = await fetch(`${httpService.BaseUrl}api/users/change-password`, requestOptions);

            if (response.status === 201) {
                alert("Password changed!");
            }
            else {
                console.log(response);
                alert("Failed");
            }
        }
    }

    render() {
        return (
            <div>
                <div className="header">
                    <h1>Change password</h1>
                </div>
                <p>Enter your new password:</p>
                <Form>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" placeholder="Password..." value={this.state.password} onChange={this.handleChange} />
                    </Form.Group>
                    <Form.Group controlId="formBasicConfirmPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="confirmPassword" placeholder="Confirm password..." value={this.state.confirmPassword} onChange={this.handleChange} />
                    </Form.Group>
                    <Button variant="primary" onClick={this.handleClick}>
                        Change password
                    </Button>
                </Form>
            </div>
        );
    }
}
