import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import authService from '../api/AuthorizeService';
import { Redirect, Link } from 'react-router-dom';
import { Organization } from '../models/organization';
import { Col, Row, Spinner } from 'react-bootstrap';


interface RegisterProps {

}

interface RegisterState {
    clubName?: string;
    email?: string;
    password?: string;
    confirmPassword?: string;
    acceptTOS: boolean;
    waiting: boolean;
    registerSuccess?: boolean;
    registrationRequestId?: string;
    trial: boolean;
}

export class Register extends Component<RegisterProps, RegisterState> {

    constructor(props: RegisterProps) {
        super(props);
        this.state = {waiting: false, acceptTOS: false, registerSuccess: false, trial: false};
        this.handleClick = this.handleClick.bind(this);
        this.registerClick = this.registerClick.bind(this);
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value, waiting: this.state.waiting, acceptTOS: this.state.acceptTOS, trial: this.state.trial });
    }

    async handleClick() {
        console.log(this.state);
        if (this.state.email && this.state.password && this.state.confirmPassword && this.state.clubName) {
            console.log("Hej");
            const response = await authService.registerWithClub(this.state.email, this.state.password, this.state.confirmPassword, this.state.clubName);
        }
    }

    async registerClick(trial: boolean) {
        if (this.state.email && this.state.password && this.state.confirmPassword) {
            if (this.verifyPasswordStrength(this.state.password, this.state.confirmPassword)) {
                this.setState({waiting: true, trial: trial});                
                const response = await authService.register(this.state.email, this.state.password, this.state.password, undefined);
                console.log(response);
               
                if (response.ok) {
                    let data = await response.json();
                    this.setState({registerSuccess: true, registrationRequestId: data.registrationRequestId});
                }
                else {
                    let data = await response.text();
                    alert("Something went wrong: " + data);
                }
                this.setState({waiting: false});
            }
            else {
                alert("Your password is not strong enough");
            }
        }
    }

    verifyPasswordStrength(password: string, confirmPassword: string): boolean {
        if (password !== confirmPassword) {
            return false;
        }

        if (password.length < 6) {
            return false;
        }

        return true;
    }

    checkPasswordStrength(password?: string) {
        if (password) {
            if (password.length < 6) {
                return { passwordStrengthElement: <span className="text-danger">Password must be at least 6 characters long</span>, passwordStrengthValid: false };
            }
            if (password.length < 10) {
                return  { passwordStrengthElement: <span className="text-warning">Consider making your password longer to increase security</span>, passwordStrengthValid: true };
            }
            else {
                return { passwordStrengthElement: <span className="text-success">Looks good!</span>, passwordStrengthValid: true };
            }
        }
        return { passwordStrengthElement: <div></div>, passwordStrengthValid: false };
     
    }

    validateEmail(email: string) {
        const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return re.test(String(email).toLowerCase());
    }

    checkIsEmail(email?: string) {
        if (email) {
            if (!this.validateEmail(email)) {
                return { emailMatchesElement: <span className="text-danger">Enter valid email</span>, emailMatchesValid: false };
            }
            else {
                return {emailMatchesElement: <span className="text-success">Looks good!</span>, emailMatchesValid: true };
            }
        }
        return  {emailMatchesElement: <div></div>, emailMatchesValid: false };
    }

    private renderRegister = () => {
        const { emailMatchesElement, emailMatchesValid } = this.checkIsEmail(this.state.email);
        const {passwordStrengthElement,  passwordStrengthValid} =  this.checkPasswordStrength(this.state.password);
        var passwordMatchesElement = <div></div>;
        let passwordMatchesValid = false;
        if (this.state.password && this.state.confirmPassword && this.state.confirmPassword.length > 0) {
            if (this.state.password === this.state.confirmPassword) {
                passwordMatchesElement = (<span className="text-success">Passwords match</span>);
                passwordMatchesValid = true;
            }
            else {
                passwordMatchesElement = (<span className="text-danger">Passwords doesn't match</span>);
            }
        }

        return (
            <div>
               <p>Create an account to start using the scoreboard.</p>
                <p>Enter your email to start registration:</p>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control type="email" name="email" placeholder="Enter email" value={this.state.email} onChange={this.handleChange} />
                        <div className="input-validation">
                            {emailMatchesElement}
                        </div>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" placeholder="Password..." value={this.state.password} onChange={this.handleChange} />
                        <div className="input-validation">
                            {passwordStrengthElement}
                        </div>
                    </Form.Group>
                    <Form.Group controlId="formBasicConfirmPassword">
                        <Form.Label>Confirm password</Form.Label>
                        <Form.Control type="password" name="confirmPassword" placeholder="Confirm password..." value={this.state.confirmPassword} onChange={this.handleChange} />
                        <div className="input-validation">
                            {passwordMatchesElement}
                        </div>
                    </Form.Group>
                   
                       <div className="d-flex">
                           
                            <Form.Check type="checkbox" checked={this.state.acceptTOS} onClick={() => this.setState({acceptTOS: !this.state.acceptTOS})}/> 
                            <span>I have read and accept the <Link to="/tos" target="_blank"> Terms and Conditions</Link></span>
                       </div>
                        
                    
                    <div className="mt-4 d-flex">                     
                        <Button variant="primary" onClick={() => this.registerClick(false)} disabled={this.state.waiting || !this.state.acceptTOS || !emailMatchesValid || !passwordStrengthValid || !passwordMatchesValid}>
                            {this.state.waiting && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            }
                            <span className="mx-2">Register</span>
                        </Button>
                        <p className='mx-4 my-1'><b>OR</b></p>
                        <Button variant="outline-dark" onClick={() => this.registerClick(true)} disabled={this.state.waiting || !this.state.acceptTOS || !emailMatchesValid || !passwordStrengthValid || !passwordMatchesValid}>
                            {this.state.waiting && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            }
                            <span className="mx-2">Request 30 day trial</span>
                        </Button>
                    </div>
                   
                </Form>
            </div>
        );
    }

    render() {

        if (this.state.registerSuccess && this.state.registrationRequestId) {
            return (
                <Redirect to={`/registration/${this.state.registrationRequestId}${this.state.trial ? "?trial=true" : ""}`} />
            );
        }
      
        let content = this.state.registerSuccess ? <p>Registered succesfully! Check your email to continue the process.</p> : this.renderRegister();
        
        return (
            <div>
                 <div className="header">
                    <h1>Register</h1>
                </div>
            {content}
          </div>
        );

        // Currently only super admins can register new clubs
        if (!authService.isSuperAdmin()) {
            return (
                <div>
                    <h2>Register</h2>
                    <p>Contact <a href="mailto:danieljallov@gmail.com">danieljallov@gmail.com</a> to get started!</p>
                </div>)
        }
        return (
            <Form>
                <Form.Group controlId="formBasicClubname">
                    <Form.Label>Club name</Form.Label>
                    <Form.Control type="email" name="clubName" placeholder="Enter club name" value={this.state.clubName} onChange={this.handleChange} />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" name="email" placeholder="Enter email" value={this.state.email} onChange={this.handleChange} />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" name="password" placeholder="Password..." value={this.state.password} onChange={this.handleChange} />
                </Form.Group>
                <Form.Group controlId="formBasicConfirmPassword">
                    <Form.Label>Confirm password</Form.Label>
                    <Form.Control type="password" name="confirmPassword" placeholder="Confirm password..." value={this.state.confirmPassword} onChange={this.handleChange} />
                </Form.Group>
                <Button variant="primary" onClick={this.handleClick}>
                    Register
                </Button>
            </Form>
        );
    }
}