import React, { Component } from 'react';
import { LicenseEntity } from '../models/license-entity';
import httpService from '../api/HttpService';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { AddLicenseModal } from './add-license-modal';
import moment from "moment";
import { ConfirmModal } from './confirm-modal';
import CSS from 'csstype';
import authService from '../api/AuthorizeService';
import { UserLevel } from '../models/user';
import { Link } from 'react-router-dom';
import { Organization } from '../models/organization';
import { StreamOverlayModal } from './stream-overlay-modal';

interface Props {
    organizationId: string;
}

interface State {
    isReady: boolean;
    licenses: LicenseEntity[];
    showModal: boolean;
    showRenameModal: boolean;
    showConfirmDelete: boolean;
    showConfirmRevoke: boolean;
    editLicense?: LicenseEntity;
    organization?: Organization;    
    streamModalLicense?: LicenseEntity;
}

interface PostLicense {
    name: string;
    organizationId: string;
}

export class LicenseCard extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isReady: false,
            showModal: false,
            showRenameModal: false,
            showConfirmDelete: false,
            showConfirmRevoke: false,
            licenses: []
        };
    }

    private _apiPath: string = 'api/LicenseEntities';

    componentDidMount() {
        this.loadData();
        this.loadOrganization();
    }

    private revokeClick = (license: LicenseEntity): void => {
        this.setState({ showConfirmRevoke: true, editLicense: license });
    };

    private deleteClick = (license: LicenseEntity): void => {
        this.setState({ showConfirmDelete: true, editLicense: license });
    };

    private streamClick = (license: LicenseEntity): void => {

    }

    private renameClick = (license: LicenseEntity): void => {
        this.setState({showRenameModal: true, editLicense: license});
    };

    private confirmDelete = async (accept: boolean): Promise<void> => {
        if (!this.state.editLicense || !accept) {
            this.setState({ showConfirmDelete: false, editLicense: undefined });
            return;
        }
        const deleted = await httpService.delete<LicenseEntity>(this._apiPath, this.state.editLicense.id);
        this.setState({ showConfirmDelete: false, editLicense: undefined });
        this.loadData();
    }

    private confirmRevoke = async (accept: boolean): Promise<void> => {
        if (!this.state.editLicense || !accept) {
            this.setState({ showConfirmRevoke: false, editLicense: undefined });
            return;
        }
        let license = this.state.editLicense;
        license.isActive = false;
        license.authorizationKey = undefined;
        const revoked = await httpService.revokeLicense<LicenseEntity>(this._apiPath, this.state.editLicense.id, this.state.editLicense);
        this.setState({ showConfirmRevoke: false, editLicense: undefined });
        this.loadData();
    }

    private formatDate = (date: string): string => {
        const m = moment(date);
        return m.format('DD/MM/YYYY HH:mm');
    }

    private checkValidDate = (date: string): string => {
        const m = moment(date);
        const now = moment();
        if (m.isAfter(now)) {
            return "Is valid";
        }
        else {
            return "Expired";
        }
    }

    renderLicenseTable(licenses: LicenseEntity[]): JSX.Element {
        let user = authService.getUser();
        const admin = user.userLevel == UserLevel.AppLogin || user.userLevel == UserLevel.None;
        const livescoreEnabled: boolean = this.state.organization != null && this.state.organization.organizationOptions.livescoreEnabled;
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Activation date</th>
                        <th>Validity</th>
                        <th>Live</th>
                        {authService.isAdmin() &&           
                        <th>Action</th>
                        }
                        {authService.isAdmin() &&                       
                        <th></th>
                        }                       
                         {authService.isAdmin() && livescoreEnabled &&                       
                        <th></th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {licenses.map((license: LicenseEntity) =>
                        <tr>
                            <td>{license.name}</td>
                            <td>{license.activationDate && this.formatDate(license.activationDate)}</td>
                            <td>{license.expirationDate && this.checkValidDate(license.expirationDate)}</td>
                            <td className="center-cell">
                                <input className="form-check-input" checked={livescoreEnabled} disabled={true} type="checkbox"></input>
                            </td>
                            {authService.isAdmin() &&       
                            <td>
                                {license.authorizationKey ?
                                    <button type="button" className="btn btn-warning btn-sm"  onClick={() => this.revokeClick(license)}>Revoke</button>
                                    :
                                    <div>Not in use</div>
                                }
                            </td>   
                            }
                            {authService.isAdmin() &&                               
                            <td>
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.renameClick(license)}>Rename</button>
                            </td>
                            }                       
                            {livescoreEnabled && authService.isAdmin() &&
                            <td>
                                {/* <Link to={`/stream/${license.id}?fontSize=26&shorten=0&upperCase=1&delay=0`}>Stream overlay</Link>        */}
                                <button type="button" className="btn btn-secondary btn-sm"  onClick={() => this.setState({streamModalLicense: license})}>Stream overlay</button>                     
                            </td>
                            }
                        </tr>

                    )}
                </tbody>
            </table>
        );
    }

    showModal(show: boolean) {
        this.setState({
            showModal: show
        });
        console.log("Show modal", this.state);

    }

    showRenameModal(show: boolean) {
        this.setState({
            showRenameModal: show
        });
    }

    private updateShowLivescore = async(license: LicenseEntity): Promise<void> => {
        license.showLivescore = !license.showLivescore;
        const resp = await httpService.put<LicenseEntity>(this._apiPath, license.id, license);
        this.loadData();        
    }

    private renameLicense = async (name: string, license: LicenseEntity): Promise<void> => {
        this.showRenameModal(false);
        license.name = name;
        const resp = await httpService.put<LicenseEntity>(this._apiPath, license.id, license);
        this.loadData();
        console.log("Rename to " + name, license);
    }

    private addLicense = async (name: string): Promise<void> => {
        this.showModal(false);

        if (!name || name.length == 0) {
            return;
        }

        const license: PostLicense = {
            name: name,
            organizationId: this.props.organizationId
        };

        const resp = await httpService.post<PostLicense>(this._apiPath, license);
        this.loadData();
    };

    render() {
        const content = this.state.isReady ? this.renderLicenseTable(this.state.licenses) : <p><em>Loading...</em></p>

        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Licenses</h5>
                    {content}
                    {authService.isSuperAdmin() &&
                    <Button variant="primary" onClick={() => this.showModal(true)}>
                        Create license
                    </Button>
                    }
                </div>
                <AddLicenseModal title="Create license" show={this.state.showModal} onClose={() => this.showModal(false)} onAddClick={this.addLicense} />
                <AddLicenseModal title="Rename license" content={this.state.editLicense?.name} show={this.state.showRenameModal} onClose={() => this.showRenameModal(false)} onAddClick={(name) => this.renameLicense(name, this.state.editLicense!)} />
                <ConfirmModal show={this.state.showConfirmDelete} message="Are you sure you wish to delete this license?" onClose={this.confirmDelete} />
                <ConfirmModal show={this.state.showConfirmRevoke} message="Are you sure you wish to revoke this license?" onClose={this.confirmRevoke} />
                <StreamOverlayModal license={this.state.streamModalLicense} onClose={() => this.setState({streamModalLicense: undefined})} />
            </div>
        );
    }

    private loadData = async (): Promise<void> => {
        const licenses = await httpService.get<LicenseEntity[]>(`${this._apiPath}?organizationId=${this.props.organizationId}`);

        this.setState({
            isReady: true,
            showModal: this.state.showModal,
            licenses: licenses
        });
    }

    private loadOrganization = async () => {
        const url = `api/organizations/${this.props.organizationId}`;
        console.log(url);
        let org = await httpService.get<Organization>(url);

        this.setState({ organization: org });
    }

}