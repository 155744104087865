import React from 'react';

interface Props {
    text: string;
}

export const Title = (props: Props) => {
    return (
        <div className="header">
                <h1>{props.text}</h1>
        </div>
    );
}