import { AuthToken } from "../models/auth-token";
import httpService from "./HttpService";
import { LoginRequest } from "../models/login-request";
import moment from "moment";
import { UserLevel, User } from "../models/user";
import { RegisterRequest } from "../models/register-request";

interface Callback {
    callback: () => void;
    subscriptionId: number;
}

export class AuthorizeService {
    // private _baseUrl: string = "https://localhost:5001/";

    private _callbacks: Callback[] = [];
    private _nextSubscriptionId: number = 0;

    public test = (s: string): string => {
        return s + s;
    }

    public authenticate = async (username: string, password: string): Promise<boolean> => {
        const loginRequestModel: LoginRequest = {
            grantType: "login",
            username: username,
            password: password
        };

        let formBody = [];
        formBody.push(encodeURIComponent('grantType') + "=" + encodeURIComponent('login'));
        formBody.push(encodeURIComponent('username') + "=" + encodeURIComponent(username));
        formBody.push(encodeURIComponent('password') + "=" + encodeURIComponent(password));

        let urlEncoded = formBody.join("&");

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: urlEncoded
        };

        const response: Response = await fetch(`${httpService.BaseUrl}api/users/authenticate`, requestOptions);
        let token: AuthToken = await response.json();

        if (response.ok) {
            localStorage.setItem("token", JSON.stringify(token));
        }

        const expireDate = moment('2020-04-23T21:02:39.7596622Z');
        console.log(expireDate);

        this.notifySubscribers();

        return response.ok;
    }

    public refresh = async (): Promise<boolean> => {
        const refreshToken = this.getToken().refreshToken;
        let formBody = [];
        formBody.push(encodeURIComponent('grantType') + "=" + encodeURIComponent('refresh_token'));
        formBody.push(encodeURIComponent('refreshToken') + "=" + encodeURIComponent(refreshToken));

        let urlEncoded = formBody.join("&");

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: urlEncoded
        };

        const response: Response = await fetch(`${httpService.BaseUrl}api/users/authenticate`, requestOptions);
        let token: AuthToken = await response.json();

        if (response.ok) {
            localStorage.setItem("token", JSON.stringify(token));
        }

        const expireDate = moment('2020-04-23T21:02:39.7596622Z');
        console.log(expireDate);

        this.notifySubscribers();

        return response.ok;
    }

    public register = async (email: string, password: string, confirmPassword: string, organizationId?: string): Promise<Response> => {
        const registerRequestModel: RegisterRequest = {
            username: email,
            password: password,
            confirmPassword: confirmPassword,
            organizationId: organizationId
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(registerRequestModel)
        };

        const response: Response = await fetch(`${httpService.BaseUrl}api/users/register`, requestOptions);

        return response;
    }

    public registerWithClub = async (email: string, password:string, confirmPassword: string, clubName: string) => {
        console.log("registerWithClub: " + clubName);
        const registerRequestModel: RegisterRequest = {
            username: email,
            password: password,
            confirmPassword: confirmPassword
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(registerRequestModel)
        };

        const response: Response = await fetch(`${httpService.BaseUrl}api/users/register?createNewOrganization=true&clubName=${clubName}`, requestOptions);

        return response.ok;
    }

    public registerNewUser = async (email: string) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };

        const response: Response = await fetch(`${httpService.BaseUrl}api/users/new?email=${email}`, requestOptions);

        return response.ok;
    }

    public signOut = (): void => {
        localStorage.removeItem("token");
        this.notifySubscribers();
    }

    private getToken = (): AuthToken => {
        let token = localStorage.getItem("token");
        return token && JSON.parse(token);       
    }

    public getBearerToken = (): string => {
        let token: AuthToken = this.getToken();
        return token && token.token;
    }

    public isAuthenticated = (): boolean => {
        let token: AuthToken = this.getToken();
        if (token) {
            const expireDate = moment(token.expiresAt);
            const now = moment();
            return now.isBefore(expireDate);
        }
        return false;
    }

    public getUser = (): User => {
        let token: AuthToken = this.getToken();
        return token && token.userInfo; 
    }

    public isSuperAdmin = (): boolean => {
        return this.isUserLevel(UserLevel.SuperAdmin);
    }

    public isLocalAdmin = (): boolean => {
        return this.isUserLevel(UserLevel.LocalAdmin);
    }

    public isAdmin = (): boolean => {
        return this.isUserLevel(UserLevel.SuperAdmin) || this.isUserLevel(UserLevel.LocalAdmin);
    }

    private isUserLevel = (userLevel: UserLevel): boolean => {
        let token: AuthToken = this.getToken();
        return token && token.userInfo.userLevel == userLevel;
    }

    public subscribe(callback: () => void) {
        this._callbacks.push({ callback: callback, subscriptionId: this._nextSubscriptionId++ });
    }

    public unsubscribe(subscriptionId: number): void {
        const subscriptionIndex = this._callbacks
        .map((element, index) => element.subscriptionId === subscriptionId ? { found: true, index } : { found: false })
        .filter(element => element.found === true);

        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index!, 1);
    }

    public notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;