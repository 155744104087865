import React, { FocusEventHandler, useReducer, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

interface Props {

}

export const StreamGuide = (props: Props) => {
    let [software, setSoftware] = useState("obs");

    const addSource: string = `${software}-add-source.png`;
    const sourceUrl: string = `${software}-source-url.png`;
    const positionOverlay: string = `${software}-position-overlay.png`;
    const editCss: string = `${software}-edit-css.png`;

    return (
        <div>
            <div className="header">
                <h1>
                    Stream guide
                </h1>
            </div>
            <p>This guide will show you how to easily get started using the stream overlay for your own live streams, using either <a href="https://obsproject.com">OBS Studio</a>, <a href="https://streamlabs.com">Streamlabs</a> or <a href="https://www.xsplit.com/broadcaster">Xsplit Broadcaster</a>.</p>
            <p>Other broadcasting softwares should also be able to work with the 147board score overlay, as long as it has a Browser source.</p>



            <div className="header">
                <h2>
                    Configure stream options
                </h2>
            </div>

            <h4>Find table</h4>
            <p>Log in to the dashboard and click on the Stream overlay button on the table that you wish to add scores from. Make sure that the Live checkbox is checked.</p>
            <img src="dashboard-stream-overlay-button.png" className="img-fluid mb-4"></img>

            <h4>Configure score image</h4>
            <p>In the popup windows you can adjust the parameters for how the score image should look. Font size determines how big the text of the player names are. If Shorten first name is checked, the first name will only appear with one letter and a dot. If Uppercase is checked, all letters will be upper case.</p>
            <p>You can preview the score image below, and you can adjust the width of the preview. You should set the width to be the same width that you will use when streaming (see sections below).</p>
            <p><b>Notice the delay property.</b> If you have a delay before the camera image reaches your broadcasting software, you can add the delay here (in seconds) so that the score will wait before displaying in order to match the video.</p>
            <p>When you are happy with the look of the score image, you click on the link or the copy button, and the score image link will be copied to your clipboard. You are now ready to set up your broadcasting software and paste in the link there (see below).</p>
            <img src="dashboard-stream-overlay-select.png" className="img-fluid mb-4"></img>
            <div className="header">
                <h2>
                    Add overlay to broadcasting software
                </h2>
            </div>

            <div className="mb-4">
                <p>Select your broadcasting software:</p>
                <Button variant={software === "obs" ? "primary" : "outline-primary"} onClick={() => setSoftware('obs')}>OBS</Button>
                <Button className="mx-2" variant={software === "stream-labs" ? "primary" : "outline-primary"} onClick={() => setSoftware('stream-labs')}>Stream labs OBS</Button>
                <Button variant={software === "xsplit" ? "primary" : "outline-primary"} onClick={() => setSoftware('xsplit')}>Stream labs OBS</Button>
            </div>

            <h4>Add browser source</h4>
            <p>In the Sources section, click on the + to add a new {software === "xsplit" ? "Webpage" : "Browser"} source.</p>
            <img src={addSource} className="img-fluid mb-4"></img>

            <h4>Paste in the stream url</h4>
            <p>Paste the url you copied earlier into the URL field {software === "xsplit" ? "." : ", and set the width to the desired value. Set the height to 80 and leave the Custom CSS as it is."}</p>
            <img src={sourceUrl} className="img-fluid  mb-4"></img>

            {software === "xsplit" &&
                <div>
                    <h4>Edit resolution and CSS</h4>
                    <p>Right click on the Webpage source to bring up the editor view. Under Display, Resolution choose Custom and set it to the desired width, and set the height to 100.</p>
                    <p>You will notice a white background around the score. To remove this, set a check in the Use Custom CSS checkbox, and click on Edit CSS. In the form, paste in the following line and click Overwrite CSS:</p>
                    <p>
                        <div className="code-format" style={{display:"inline-block"}}>body &#123; background-color: rgba(0, 0, 0, 0); margin: 0px auto; overflow: hidden; &#125;</div>
                    </p>
                    <img src={editCss} className="img-fluid  mb-4"></img>
                </div>}

            <h4>Position the score image</h4>
            <p>Grab the score image and move it around to position it correctly. It is possible to adjust the size of it by dragging the corners, but this should only be done if the score image needs to be taller. Otherwise you should change the width by editing the browser source and changing the width, as described in the section above.</p>
            <img src={positionOverlay} className="img-fluid mb-4"></img>

            <h4>Done!</h4>
            <p>The score overlay is now added to your streaming setup. Repeat this process for any other tables you wish to stream with score from.</p>
        </div>
    )
}