import { CS_Tournament } from "../models/cuescore-models";

export class CuescoreService {
    private BaseUrl: string = "https://api.cuescore.com/"; //

    async getTournament(id:string) : Promise<CS_Tournament> {
        const apiPath: string = `tournament/?id=${id}`;
        const response = await fetch(`${this.BaseUrl}${apiPath}`);
        let data = await response.json();
        return data;
    };
}


const cuescoreService = new CuescoreService();

export default cuescoreService;