import React, { Component, ChangeEvent } from 'react';
import { Organization } from '../models/organization';
import { RouteComponentProps } from 'react-router-dom';
import httpService from '../api/HttpService';
import { UIConfig } from '../models/uiConfig';
import authService from '../api/AuthorizeService';
import Button from 'react-bootstrap/Button';
import { SkinPreview } from './skin-preview';



interface MatchParams {
    orgId: string;
}

interface Props extends RouteComponentProps<MatchParams> {

}

interface State {
    organizationId: string;
    organization?: Organization;
    skins?: UIConfig[];
    file?: FormData;
    selectedSkin?: UIConfig;
}

export class EditSKin extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        let orgId: string = this.props.match.params.orgId;
        if (!authService.isAuthenticated() || orgId.length <= 0) {
            this.props.history.push('/login');
            return;
        }

        this.state = {
            organizationId: orgId
        };

    }

    componentDidMount() {
        this.loadData();
        this.loadOrgData();
    }

    private editSkin = (skin: UIConfig) => {
        this.setState({
            ...this.state,
            selectedSkin: skin
        });
    }

    renderSkinList(skins: UIConfig[]): JSX.Element {
        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Skins</h5>
                    <table className='table table-striped' aria-labelledby="tabelLabel">
                        <tbody>
                            {skins.map((skin: UIConfig) => 
                                <tr>
                                    <td>{skin.name}</td>
                                    <td>
                                        <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => this.editSkin(skin)}>Edit</button>
                                    </td>
                                    <td>
                                        <button type="button" className="btn btn-danger btn-sm">Delete</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>      
                    <Button variant="primary" onClick={this.createSkin}>
                        Create
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                <div className="header">
                    <h1>Edit skin</h1>
                </div>
                { this.state.skins &&
                    this.renderSkinList(this.state.skins)
                }
                { this.state.selectedSkin &&
                    <SkinPreview skin={this.state.selectedSkin}></SkinPreview>

                }
               
            </div>
        );
    }

    private createSkin = async() => {
        let defaultSkin: UIConfig = {
            id: "",
            createdAt: "2020-06-16T21:45:42.708958",
            updatedAt: "2020-06-16T21:45:42.708958",
            deleted: false,
            dividerColorHex: "#FFFFFF",
            backgroundColorHex: "#333333",
            organizationId: this.state.organizationId
        };

        let skin = await httpService.post<UIConfig>('api/UIconfigs', defaultSkin);
        let skins = this.state.skins;
        if (!skins) {
            skins = [];
        }
        skins.push(skin);

        this.setState({
            ...this.state,
            skins: skins
        });
    }

    private loadOrgData = async() => {
        const url = `api/organizations/${this.state.organizationId}`;
        let org = await httpService.get<Organization>(url);

        this.setState({
            ...this.state,
            organization: org
        });
    }

    private loadData = async() => {
        const url = `api/UIconfigs?organizationId=${this.state.organizationId}`;
        let skins = await httpService.get<UIConfig[]>(url);

        this.setState({
            ...this.state,
            skins: skins
        });
    }
}