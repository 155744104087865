import React, { useEffect, useState } from 'react';
import { MatchResult } from '../models/match-result';
import httpService from '../api/HttpService';
import { MatchReport } from './match-report';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { SelectCallback } from 'react-bootstrap/helpers';
import { Organization } from '../models/organization';

interface Props {
    organizationId?: string;
    tournamentId?: string;
}

export const MatchReportCard = (props: Props) => {

    const [matches, setMatches] = useState<MatchResult[]>();
    const [minHighBreak, setMinHighBreak] = useState("30");
    const [tournamentNames, setTournamentNames] = useState<string[]>();

    const fetch = async () => {
        const org = await httpService.get<Organization>(`api/organizations/${props.organizationId}`);

        console.log("Yo", org);

        let url = `api/matchresults`;
        if (org?.tournamentIdentifier) {
            url += `?tournamentId=${org.tournamentIdentifier}`;
        }
        else if (props.tournamentId) {
            url += `?tournamentId=${props.tournamentId}`;
        } else if (props.organizationId) {
            url += `?organizationId=${props.organizationId}`;
        }

        // url = `api/matchresults?organizationId=${props.organizationId}`;

        let matches = await httpService.get<MatchResult[]>(url);

        setMatches(matches);

        let tournaments : string[] = [];
        for (let i = 0; i < matches.length; i++) {
            const match = matches[i];
            if (tournaments.indexOf(match.tournamentName) < 0) {
                tournaments.push(match.tournamentName);
            }
        }

        console.log(tournaments);
        setTournamentNames(tournaments);

        return matches;
    }

    useEffect(() => {
        

        fetch().catch(console.error);
    }, []);

    const handleSelect = (e: SelectCallback) => {
        console.log(e);
    }

    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">Match reports</h5>
                <Form>
                    <Form.Group as={Row} controlId="formBasicEmail">
                        <Form.Label column sm="2">Minimum highest break</Form.Label>
                        <Col sm="2">
                            <Form.Control type="number" name="username" placeholder="All" value={minHighBreak} onChange={e => setMinHighBreak(e.target.value)} />
                        </Col>
                        <Col sm="2">
                            <Button onClick={() => fetch().catch(console.error)}>Refresh</Button>
                        </Col>
                    </Form.Group>
                </Form>
                <div>
                    {tournamentNames?.map(tournament => 
                    <>
                        <h3>{tournament}</h3>
                        {matches?.filter(x => x.tournamentName === tournament).sort((a, b) => parseInt(a.matchNo) - parseInt(b.matchNo)).map(match => <MatchReport matchResult={match} minHighBreak={parseInt(minHighBreak)} />)}
                    </>)}

                </div>
            </div>
        </div>
    );
}