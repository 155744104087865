import React from 'react';
import { useEffect, useState } from 'react';
import httpService from '../api/HttpService';
import { Organization } from '../models/organization';
import TournamentList from './tournament-list';
import TournamentAdmin from './tournament-admin';

interface TournamentCardProps {
    organizationId: string;
}

const TournamentCard: React.FC<TournamentCardProps> = ({ organizationId }) => {
    const [organization, setOrganization] = useState<Organization>();
    const [tournamentId, setTournamentId] = useState<string>();  

    const fetchClick = async (tournamentId?: string) => {
        if (organization) {
            const org = { ...organization, tournamentIdentifier: tournamentId };
            await httpService.put<Organization>("api/organizations", organizationId, org);
            fetchOrganization();
        }
    };

    const fetchOrganization = async () => {
        const url = `api/organizations/${organizationId}`;
        let org = await httpService.get<Organization>(url);
        setOrganization(org);
        setTournamentId(org?.tournamentIdentifier);
    };

    useEffect(() => {
        fetchOrganization();
    }, []);

    return (
        <div className='card'>
            <div className='card-body'>
                {organization &&
                    <>
                        <TournamentAdmin tournamentId={organization.tournamentIdentifier} fetchClick={fetchClick} organization={organization} />
                        {tournamentId &&
                            <TournamentList organizationId={organizationId} organization={organization} tournamentId={tournamentId} />
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default TournamentCard;