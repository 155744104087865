import React from 'react';
import { LiveScore } from '../models/livescore';
import { isMobile } from 'react-device-detect';

interface Props {
    liveScore: LiveScore;
    width?: number;
    fontSize?: number;
}

export const LivescoreCard = (props: Props) => {

    const content: JSX.Element = isMobile ? renderMobileView(props) : renderBrowserView(props);
    // const ballsInBreak: JSX.Element = renderBallsInBreakView(props.liveScore.player1BallsInBreak);
    const player1BallsInBreak: number[] = JSON.parse(props.liveScore.player1BallsInBreak);
    const player2BallsInBreak: number[] = JSON.parse(props.liveScore.player2BallsInBreak);

    let ballsInBreak: JSX.Element = <div></div>;

    if (player1BallsInBreak.length > 0) {
        ballsInBreak = renderBallsInBreakView(player1BallsInBreak);
    }
    if (player2BallsInBreak.length > 0) {
        ballsInBreak = renderBallsInBreakView(player2BallsInBreak);
    }
    // ballsInBreak = renderBallsInBreakView(player1BallsInBreak);
    let classes = "livescore-outer-container";

    if (props.width) {
        classes += " d-flex flex-column justify-content-center";
    }

    return (
        <div className="d-flex flex-row justify-content-center">
            <div className={classes} style={{ width: props.width, fontSize: props.fontSize }}>
                <div className="livescore-tablename">{props.liveScore.tableName}</div>
                {content}
                {ballsInBreak}
                <div className="d-flex flex-row justify-content-center ">
                    <div className="d-flex flex-row justify-content-center powered-label">Livescore powered by 147board.com</div>
                </div>
            </div>
        </div>
    );
}

const renderMobileView = (props: Props): JSX.Element => {
    const player1ActiveClass = props.liveScore.player1IsActive ? "livescore-active" : "";
    const player2ActiveClass = props.liveScore.player2IsActive ? "livescore-active" : "";
    const player1Score = props.liveScore.player1Score + props.liveScore.player1Break;
    const player2Score = props.liveScore.player2Score + props.liveScore.player2Break;

    const distanceView = props.liveScore.distance > 0 ? <div className="p-2 bd-highlight livescore-dark d-flex flex-column justify-content-center">({props.liveScore.distance})</div> : <div></div>;

    return (
        <div className="d-flex">
            {distanceView}
            <div className="livescore-container-mobile flex-fill">
                <div className="livescore-player livescore-right">
                    <div className="frame-score bd-highlight livescore-dark">{props.liveScore.player1FramesWon}</div> 
                    <div className="livescore-score">{player1Score}</div>
                    <div className="p-2 bd-highlight">{props.liveScore.player1FirstName} {props.liveScore.player1LastName}</div>
                    <div className="d-flex flex-column justify-content-center">
                        <div className={player1ActiveClass}></div>
                    </div>
                </div>
                <div className="livescore-player livescore-right">
                    <div className="frame-score  bd-highlight livescore-dark">{props.liveScore.player2FramesWon}</div>
                    <div className="livescore-score">{player2Score}</div>
                    <div className="p-2 bd-highlight">{props.liveScore.player2FirstName} {props.liveScore.player2LastName}</div>
                    <div className="d-flex flex-column justify-content-center">
                        <div className={player2ActiveClass}></div>
                    </div>
                </div>
            </div>

        </div>
    );
}

const renderBrowserView = (props: Props): JSX.Element => {
    const player1ActiveClass = props.liveScore.player1IsActive ? "livescore-active" : "";
    const player2ActiveClass = props.liveScore.player2IsActive ? "livescore-active" : "";
    const player1Score = props.liveScore.player1Score + props.liveScore.player1Break;
    const player2Score = props.liveScore.player2Score + props.liveScore.player2Break;
    const distance: string = props.liveScore.distance > 0 ? props.liveScore.distance.toString() : "-";
    return (
        <div className="livescore-container">

            <div className="livescore-player livescore-left">
                <div className="d-flex flex-column justify-content-center">
                    <div className={player1ActiveClass} style={{ height: props.fontSize, width: props.fontSize }}></div>
                </div>
                <div className="p-2 bd-highlight">{props.liveScore.player1FirstName} {props.liveScore.player1LastName}</div>
                <div className="livescore-score">{player1Score}</div>
                <div className="frame-score bd-highlight livescore-dark">{props.liveScore.player1FramesWon}</div>
            </div>
            <div className="p-2 bd-highlight livescore-dark">({distance})</div>
            <div className="livescore-player livescore-right">
                <div className="frame-score bd-highlight livescore-dark">{props.liveScore.player2FramesWon}</div>
                <div className="livescore-score">{player2Score}</div>
                <div className="p-2 bd-highlight">{props.liveScore.player2FirstName} {props.liveScore.player2LastName}</div>
                <div className="d-flex flex-column justify-content-center">
                    <div className={player2ActiveClass} style={{ height: props.fontSize, width: props.fontSize }}></div>
                </div>
            </div>

        </div>
    );
}

const renderBallsInBreakView = (balls: number[]): JSX.Element => {    
    return (
        <div className="balls-container">
            {balls.map((ball: number) => {
                const classes: string = `ball ball-${ball}`;
                return (
                    <div className={classes}></div>
                );
            }
            )}
        </div>
    );
}