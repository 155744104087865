import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import authService from '../api/AuthorizeService';
import Container from 'react-bootstrap/Container';
import { OrganizationInfo } from './organization-info';
import { UsersCard } from './users-card';
import { LicenseCard } from './license-card';
import TournamentCard from './tournament-card';
import { Organization } from '../models/organization';
import { PaymentFailedBanner } from './payment-failed-banner';
import { TrialBanner } from './trial-banner';
import { PriceTable } from './price-table';
import { TrialUpgrade } from './trial-upgrade';
import { MatchReportCard } from './match-report-card';
import { Col, Row } from 'react-bootstrap';

interface MatchParams {
    id: string;
}

interface DashboardProps extends RouteComponentProps<MatchParams> {

}

interface DashboardState {
    organizationId: string;
    organization?: Organization;
    showPricingModal?: boolean;
}

export class Dashboard extends Component<DashboardProps, DashboardState> {

    constructor(props: DashboardProps) {
        super(props);

        if (!authService.isAuthenticated()) {
            this.props.history.push('/login');
            return;
        }

        let orgId: string = this.props.match.params.id;

        if (orgId == null) {
            orgId = authService.getUser().organizationId!;
        }

        console.log(`Org id: ${orgId}`);

        this.state = {
            organizationId: orgId          
        };
    }

    private renderDashboard(): JSX.Element {
        return (<div>
            <div className="header">
                <h1>Dashboard</h1>
            </div>

            <Row>
                <Col xs={12} md={5}>
                    <OrganizationInfo organizationId={this.state.organizationId} />
                </Col>
                {authService.isAdmin() &&
                    <Col xs={12} md={7}>
                        <UsersCard organizationId={this.state.organizationId} />
                    </Col>
                }
            </Row>
            <div className="row pt-3">
                <div className="col">
                    <LicenseCard organizationId={this.state.organizationId} />
                </div>
            </div>
          
            <div className="row pt-3">
                <div className="col">
                    <TournamentCard organizationId={this.state.organizationId} />
                </div>
            </div>
            
            <div className="row pt-3">
                <div className="col">
                    <MatchReportCard organizationId={this.state.organizationId}  />
                </div>
            </div>
        </div>);
    }

    private renderPricing() : JSX.Element {
        return (
            <div>
                <PriceTable editable={true} selectable={true} onUpdate={this.onPriceUpdate} />
            </div>
        );
    }

    private onPriceUpdate = (numberOfTables?: number, selectedPlan?: string, currency?: string) => {
        console.log("Tables: " + numberOfTables + ", plan: " + selectedPlan + ", currency: " + currency);
    }

    render() {
        if (this.state === null) {
            return (<div></div>);
        }

        return (
            <Container>
                <PaymentFailedBanner organizationId={this.state.organizationId} />
                <TrialBanner organizationId={this.state.organizationId} onUpgradeClick={() => this.setState({ showPricingModal: true })} />

                {!this.state.showPricingModal && this.renderDashboard()}
                {this.state.showPricingModal &&  <TrialUpgrade organizationId={this.state.organizationId} onCancelClick={() => this.setState({showPricingModal: false})}/>}

            </Container>
        );
    }


}