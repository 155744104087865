import React, { useState } from 'react';
import { LiveScore } from '../models/livescore';
import { isMobile } from 'react-device-detect';
import { PriceTable } from './price-table';
import { Button, Spinner } from 'react-bootstrap';
import authService from '../api/AuthorizeService';
import httpService from '../api/HttpService';
import { loadStripe } from '@stripe/stripe-js';

interface Props {
    onCancelClick: () => void;
    organizationId: string;
}

export const TrialUpgrade = (props: Props) => {

    let [numberOfTables, setNumberOfTables] = useState(0);
    let [selectedPlan, setSelectedPlan] = useState("");
    let [currency, setCurrency] = useState("");
    let [billingPeriod, setBillingPeriod] = useState("");
    let [averagePrice, setAveragePrice] = useState(0);
    let [waiting, setWaiting] = useState(false);

    const onPriceUpdate = (numberOfTables?: number, selectedPlan?: string, currency?: string, billingPeriod?: string, averagePrice?: number) => {
        if (numberOfTables) {
            setNumberOfTables(numberOfTables);
        }
        if (selectedPlan) {
            setSelectedPlan(selectedPlan);
        }
        if (currency) {
            setCurrency(currency);
        }
        if (billingPeriod) {
            setBillingPeriod(billingPeriod);
        }
        if (averagePrice) {
            setAveragePrice(averagePrice);
        }
    }

    const registerClick = async () => {
        setWaiting(true);
        let stripe = await loadStripe('pk_live_51IiP6NDWUqmX10zN8UrsSvGsM4jSvB7OHW4ihVV0EWo4o4cUDQKsZaQ8tYj5IPAlweKvMMtHzHno1t8CR4bu4B9m00Op0eikaF');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                plan: selectedPlan,
                numberOfTables: numberOfTables,
                currency: currency,
                organizationId: props.organizationId,
                billingPeriod: billingPeriod,
                email: authService.getUser().email
            })
        };

        const response = await fetch(`${httpService.BaseUrl}api/payments/create-checkout-session`, requestOptions);

        if (response.status === 201) {
            const data = await response.json();

            stripe?.redirectToCheckout({
                sessionId: data.sessionId
            });
        }
        setWaiting(false);
    }

    const cancelClick = () => {
        props.onCancelClick();
    }

    let priceElement = <div></div>
    let disabledButton = true;
    if (averagePrice > 0 && numberOfTables > 0) {

        let curr = "€";
        let currencyModifier = 1;
        if (currency) {
            console.log("Hello");
            if (currency === "dkk") {
                console.log("Hello2");
                currencyModifier = 7.5;
                curr = "kr."
                console.log("Hello3");
            }
        }

        let monthyPrice = averagePrice * numberOfTables * currencyModifier * (billingPeriod === "year" ? 10 : 1);
        const price = monthyPrice.toFixed(monthyPrice % 1 != 0 ? 2 : 0).replace('.', currency && currency === "dkk" ? ',' : '.');

        priceElement = <h4>Total: {price} {curr} / {billingPeriod}</h4>
        disabledButton = false;
    }

    return (
        <div>
            <div className="header">
                <h2>Select plan</h2>
            </div>
            <p>Select plan and number of tables</p>
            <PriceTable editable={true} selectable={true} onUpdate={onPriceUpdate} />
            {priceElement}

            {disabledButton && <p>Select a plan to continue</p>}

            <div className='d-flex mt-4'>
                <Button variant="primary" onClick={registerClick} disabled={disabledButton}>
                    {waiting && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    }
                    <span className="mx-2">Continue to payment</span>
                </Button>

                <button type="button" className="btn btn-outline-dark mx-4" onClick={cancelClick}>Cancel</button>
            </div>
        </div>
    );
}