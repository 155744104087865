import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

interface Props {
    planName: string;
    tablePrice: number;
    basePrice: number;
    numberOfTables: number;
    features?: string[];
    selectedPlan?: string;
    onSelected?: () => void;
    currency?: string;
    billedYearly: boolean;
}

export const PriceElement = (props: Props) => {

    let basePrice = props.basePrice;
    if (props.billedYearly) {
        basePrice *= 10;
    }

    let currency = "€";
    let currencyModifier = 1;
    console.log("Currency " + props.currency);
    if (props.currency) {
        console.log("Hello");
        if (props.currency === "dkk") {
            console.log("Hello2");
            currencyModifier = 7.5;
            currency = "kr."
            console.log("Hello3");
        }
    }
    console.log("Selected currency is " + currency);
    let euro = Math.round(props.tablePrice * basePrice * currencyModifier * 100) / 100;
    const price = euro.toFixed(euro % 1 != 0 ? 2 : 0).replace('.', props.currency && props.currency === "dkk" ? ',' : '.');

    const totalEuros = Math.round(props.numberOfTables * props.tablePrice * basePrice * currencyModifier * 100) / 100;
    const totalPrice = totalEuros.toFixed(totalEuros % 1 != 0 ? 2 : 0).replace('.', props.currency && props.currency === "dkk" ? ',' : '.');

    const totalDiscount = basePrice * props.numberOfTables * currencyModifier - totalEuros;
    const totalDiscountPrice = totalDiscount.toFixed(totalDiscount % 1 != 0 ? 2 : 0).replace('.', props.currency && props.currency === "dkk" ? ',' : '.');

    const selected = props.selectedPlan === props.planName;

    return (
        <div className={`price-cell ${selected ? "price-cell-selected" : ""}`}>
            <h1>{selected && <span className="mr-2">&#10003;</span>} {props.planName}</h1>

            <div className="price-cell-middle">
                <div className="price-large-text">{price} {currency}</div>
                <div>per table</div>

                <div className="mt-3 d-flex flex-column justify-content-center">
                    <div style={{textAlign: "center"}}>
                        In total:
                    </div>
                    <div>
                        <strong>{totalPrice} {currency}</strong> / {props.billedYearly ? "year" : "month"}
                    </div>
                </div>
                {/* <div className="text-danger">({totalDiscountPrice} {currency} discount)</div> */}
            </div>
            <div className="price-cell-middle">
                {props.onSelected ?
                    <Button onClick={props.onSelected} disabled={selected} variant={selected ? "outline-primary" : "primary"}>{selected ? "Selected" : "Select"}</Button>
                    :
                    <div><Link to="/register" className="btn btn-outline-primary">Get started</Link></div>
                }
            </div>
            <div className="price-cell-features">
                {props.features &&
                    <ul>
                        {props.features.map(x =>
                            <li><span>{x}</span></li>
                        )}
                    </ul>
                }
            </div>
        </div>
    );
}