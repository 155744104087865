export interface User {
    id: string;
    firstName?: string;
    lastName?: string;
    organizationId?: string;
    email: string;
    username: string;
    userLevel: UserLevel;
}

export enum UserLevel {
    None, AppLogin, LocalAdmin, SuperAdmin
}