import React, { Component } from 'react';
import httpService from '../api/HttpService';
import { LiveScore } from '../models/livescore';
import Container from 'react-bootstrap/Container';
import { LivescoreCard } from './livescore-card';
import Button from 'react-bootstrap/Button';
import { Link, RouteComponentProps } from 'react-router-dom';
import authService from '../api/AuthorizeService';
import { OrganizationPublicDTO } from '../models/organization-public-dto';

interface Props {

}

interface State {
    organizations: OrganizationPublicDTO[];
}

export class LiveOverview extends Component<Props, State> {
    private _apiPath: string = 'api/LiveScores';

    constructor(props: Props) {
        super(props);
        this.state = {
            organizations: []
        };
    }

    componentDidMount() {
        this.loadData();
    }

    renderOrganizations(organizations: OrganizationPublicDTO[]) : JSX.Element {
        return (
            <div>
                {organizations.map((organization : OrganizationPublicDTO) => 
                    <p><Link to={`/live/${organization.shortName}`}>{ organization.name }</Link></p>
                )}
            </div>
        );
    }

    render() {
        return (
            <div>
                  <div className="header">
                    <h1>Livescore overview</h1>
                </div>
                {this.renderOrganizations(this.state.organizations)}
            </div>
        )
    }

    private loadData = async (): Promise<void> => {
        const organizations = await httpService.anonGet<OrganizationPublicDTO[]>("api/organizations/all");

        this.setState({
            organizations: organizations
        });
    }
}