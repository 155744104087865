import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';


interface Props {

}

interface State {
    organizationId: string;
}

export class About extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <div>
                <div className="header">
                    <h1>About 147 board</h1>
                </div>
                <p>147board is a modern snooker scoreboard that looks great in your club and adds smart functions such as livescoring and live stream overlays.</p>
                <p>147board is a software package that is installed on a Windows computer. To get up and running, you need a computer with a monitor and mouse/keyboard per table and an internet connection.</p>
                <div className="header">
                    <h2>System requirements</h2>
                </div>
                <Row>
                    <Col md={5}>
                        <p>Per table:</p>
                        <ul>
                            <li>Computer running Windows 10 (64-bit)</li>
                            <li>Monitor</li>
                            <li>Mouse/keyboard combo</li>
                            <li>Internet connection</li>
                        </ul>

                        <p>To stream, you need a computer with a good CPU and maybe a dedicated graphics card, and a broadcasting software that can combine your camera image with the score overlay from 147board.com.</p>
                        <p>Note that the scoreboard computer and the stream computer can be the same. It is often desirable to have a dedicated streaming computer, which can be placed in a separate room to allow for commentary without disturbing the players.</p>

                    </Col>
                    <Col md={7}>
                        <img src="per-table.png" className="img-fluid"></img>

                    </Col>
                </Row>

                <div className="header">
                    <h2>How does it work?</h2>
                </div>

                <img src="how-does-it-work.png" className="img-fluid"></img>

                <div className="header">
                    <h2>Different kinds of setup</h2>
                </div>

                <p>There are many ways to setup streaming with score overlay from 147board.com, depending on how many tables you want to stream at the same time and which camera solution you are using. One of the big strenghts of the 147board system is that the players themselves can keep the score without the need for a referee.</p>

                <div className="header-h3">
                    One computer per table with webcam
                </div>
                <p>When using one computer per table for both scoreboard and streaming, the computer needs to be powerful enough to stream at your preferred resolution. This is a good solution for smaller events where you are interested in showing the matches with the score overlay, but not do anything fancy with the broadcast.</p>

                <div className="header-h3">One computer per table for scoreboard, one computer for streaming all tables</div>
                <p>At each table you place a small cheap computer or laptop which runs the 147board Scoreboard software. Somewhere else, you have a dedicated powerful streaming computer which receives the camera input from one or more tables and overlays the score on each table.</p>
                <p>This is an ideal setup for more advanced productions where you want to have a producer that can switch between different camera angles or show ads or information in between matches. This setup works especially well when you want commentators on the game, so they can sit in a different room and not disturb the players.</p>
            </div>
        );
    }
}