import React, { useState } from 'react';
import { MatchResult } from '../models/match-result';
import { Button } from 'react-bootstrap';

import { isMobile } from 'react-device-detect';

interface Props {
    matchResult: MatchResult;
    minHighBreak?: number;
}

export const MatchReport = (props: Props) => {
    const { matchResult } = props;
    const bestOf = Math.max(matchResult.player1Frames, matchResult.player2Frames) * 2 - 1;
    const maxNumberOfBreaks = 5;
    const [showDetails, setShowDetails] = useState(false);

    let p1HighestBreak = 0;
    matchResult.player1FrameResults.forEach(result => {

        p1HighestBreak = Math.max(p1HighestBreak, Math.max(...result.breaks));

    });

    let p2HighestBreak = 0;
    matchResult.player2FrameResults.forEach(result => {

        p2HighestBreak = Math.max(p2HighestBreak, Math.max(...result.breaks));

    });

    const minHighBreak = isNaN(props.minHighBreak ?? 30) ? 30 : props.minHighBreak ?? 30;

    var date = new Date(matchResult.createdAt);

    var options = { hour: '2-digit', minute: '2-digit' };
    var time = date.toLocaleString('sv', options);

    var dayOptions = { month: '2-digit', day: '2-digit' };
    var day = date.toLocaleString('sv', dayOptions);

    return (
        <div className='match-report-container'>
            {!isMobile && <>
                {matchResult.matchNo ?
                    <div className="cs-match-no"><a target='blank' href={`https://cuescore.com/tournament/a/${matchResult.tournamentId}`}>{matchResult.matchNo}</a></div>
                    :
                    <div className='cs-match-no empty-match-no' />
                }
                <div className="cs-match-time">
                    <div>
                        {day}
                    </div>
                    <div>
                        {time}
                    </div>
                </div>
            </>
            }
            <div className='match-report'>
                <div className='title'>
                    <div className={`name${isMobile ? " mobile" : ""}`}>{isMobile ? matchResult.player1?.firstName[0] + "." : matchResult.player1?.firstName} {matchResult.player1?.lastName}</div>
                    <div className='final-score'>{matchResult.player1Frames} </div>
                    <div className='final-score'>({bestOf})</div>
                    <div className='final-score'>{matchResult.player2Frames}</div>
                    <div className={`name${isMobile ? " mobile" : ""}`}>{isMobile ? matchResult.player2?.firstName[0] + "." : matchResult.player2?.firstName} {matchResult.player2?.lastName}</div>
                </div>
                <div className='frame-area'>
                    <div>
                        {showDetails &&
                            <>
                                <div className='frame-report'>
                                    <div className={`breaks${isMobile ? " mobile" : ""}`}>Breaks ({minHighBreak}+)</div><div className={`score`}>Score</div>
                                </div>
                                {matchResult.player1FrameResults.map(result =>
                                    <div className='frame-report'>
                                        <div className={`breaks${isMobile ? " mobile" : ""} divider`}>{result.breaks.filter(x => x >= minHighBreak).slice(0, maxNumberOfBreaks).join(', ')}</div><div className={`score ${result.won ? 'won' : 'lost'}`}>{result.score}</div>
                                    </div>
                                )}
                            </>
                        }

                    </div>
                    <div>
                        {showDetails &&
                            <>
                                <div className='frame-report'>
                                    <div className={`score`}>Score</div><div className={`breaks${isMobile ? " mobile" : ""} `}>Breaks ({minHighBreak}+)</div>
                                </div>
                                {matchResult.player2FrameResults.map(result =>
                                    <div className='frame-report'>
                                        <div className={`score ${result.won ? 'won' : 'lost'} divider`}>{result.score}</div><div className={`breaks${isMobile ? " mobile" : ""} `}>{result.breaks.filter(x => x >= minHighBreak).slice(0, maxNumberOfBreaks).join(', ')}</div>
                                    </div>
                                )}
                            </>
                        }

                    </div>
                </div>
                <div className='bottom'>
                    <div className={`text${isMobile ? " mobile" : ""}`}>{isMobile ? "HB:" : "Highest break:"} {p1HighestBreak}</div>
                    <div className='details'><a href='#' onClick={(event) => { event.preventDefault(); setShowDetails(!showDetails) }}>{showDetails ? "Hide" : "Show more"}</a></div>
                    <div className={`text${isMobile ? " mobile" : ""}`}>{isMobile ? "HB:" : "Highest break:"} {p2HighestBreak}</div>
                </div>

            </div>
        </div>
    );
}