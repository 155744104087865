import React from "react";
import { useState } from "react";
import { Modal, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import httpService from "../api/HttpService";

interface Props {
    onClose?: () => void;
    show: boolean;
    title: string;
}

export const SendMessageModal = (props: Props) => {
    let [email, setEmail] = useState('');
    let [name, setName] = useState('');
    let [message, setMessage] = useState('');
    let [sent, setSent] = useState(false);
    let [error, setError] = useState(false);
    let [sending, setSending] = useState(false);

    const close = () => {
        setEmail('');
        setName('');
        setMessage('');
        setSent(false);
        setError(false);
        if (props.onClose) {
            props.onClose();
        }
    };  

    const sendMessage = async () => {
        setSending(true);
        setError(false);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: name,
                email: email,
                message: message
            })
        };
        const apiPath = 'api/messages';
        const response = await fetch(`${httpService.BaseUrl}${apiPath}`, requestOptions);
        setSending(false);
        if (response.status === 201) {
            setSent(true);
            await timeout(2000);
            close();
        }
        else {
            setError(true);
        }
      
    };

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    };

    return (
        <div>
            <Modal show={props.show} centered={false} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={7} value={message} onChange={e => setMessage(e.target.value)} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {sent &&
                        <p className="flex-fill text-primary"><b>Message sent!</b></p>
                    }
                     {error &&
                        <p className="flex-fill text-danger"><b>Couldn't send message</b></p>
                    }
                    <Button className="mt-1" variant="primary" onClick={sendMessage} disabled={sending || sent}>
                        {sending && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        }
                        <span className="mx-2">Send message</span>
                    </Button>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}