import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';


interface Props {

}

interface State {
    organizationId: string;
}

export class Download extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <div>
                <div className="header">
                    <h1>Download 147 board</h1>
                </div>
                <p>Follow these steps to get up and running with 147 Board.</p>
                <div>
                    <div className="download-step">
                        <div className="header">
                            <h2>Step 1</h2>
                        </div>
                        <p>Download the installer:</p>
                        <Button href="https://147board.com/files/install-147board.exe">Download</Button>
                    </div>
                    <div className="download-step">
                        <div className="header">
                            <h2>Step 2</h2>
                        </div>
                        <p>Run the installer. If Windows warns about the file, click <b>More info</b> and then <b>Run anyway</b>.</p>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <img src={"more-info.png"} width="400"></img>
                                </Col>
                                <Col>
                                    <img src={"run-anyway.png"} width="400"></img>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="download-step">
                        <div className="header">
                            <h2>Step 3</h2>
                        </div>
                        <p>After installation is complete, log in to the Scoreboard using your app login you received when creating your account. Read more in the User Guide here:</p>
                        <Button href="/147Board_User_Guide.pdf">Download User Guide</Button>
                    </div>
                </div>
            </div>

        );
    }
}