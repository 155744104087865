import React, { useState } from 'react';
import { Modal, Button, Form, InputGroup, FormControl } from 'react-bootstrap';

interface TournamentLinkEmailModalProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: (emails: string[]) => Promise<boolean>;
}

const TournamentLinkEmailModal: React.FC<TournamentLinkEmailModalProps> = ({ visible, onClose, onSubmit }) => {
    const [emails, setEmails] = useState<string[]>(['']);

    const handleEmailChange = (index: number, value: string) => {
        const newEmails = [...emails];
        newEmails[index] = value;
        setEmails(newEmails);
    };

    const handleAddEmail = () => {
        setEmails([...emails, '']);
    };

    const handleRemoveEmail = (index: number) => {
        const newEmails = emails.filter((_, i) => i !== index);
        setEmails(newEmails);
    };

    const handleSubmit = async () => {
        await onSubmit(emails.filter(email => email.trim() !== ''));
        closeClick();
    };

    const closeClick = () => {
        setEmails(['']);
        onClose();
    };

    return (
        <Modal show={visible} onHide={onClose} onEntered={() => {
            const firstEmailInput = document.querySelector(`#email-0`);
            if (firstEmailInput) {
                (firstEmailInput as HTMLElement).focus();
            }
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Send Tournament Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {emails.map((email, index) => (
                        <Form.Group key={index} controlId={`email-${index}`}
                            onKeyDown={(e: React.KeyboardEvent) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleAddEmail();
                                    setTimeout(() => {
                                        const lastEmailInput = document.querySelector(`#email-${emails.length}`);
                                        if (lastEmailInput) {
                                            (lastEmailInput as HTMLElement).focus();
                                        }
                                    }, 0);
                                }
                            }}
                        >
                            <Form.Label>Email {index + 1}</Form.Label>
                            <InputGroup>
                                <FormControl
                                    type="email"
                                    value={email}
                                    autoFocus
                                    onChange={(e) => handleEmailChange(index, e.target.value)}
                                />
                                {emails.length > 1 && (
                                    <InputGroup.Append>
                                        <Button variant="outline-danger" onClick={() => handleRemoveEmail(index)}>
                                            Remove
                                        </Button>
                                    </InputGroup.Append>
                                )}
                            </InputGroup>
                        </Form.Group>
                    ))}
                    <Button variant="outline-primary" onClick={() => {
                        handleAddEmail();
                        setTimeout(() => {
                            const lastEmailInput = document.querySelector(`#email-${emails.length}`);
                            if (lastEmailInput) {
                                (lastEmailInput as HTMLElement).focus();
                            }
                        }, 0);
                    }}>
                        Add Email
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TournamentLinkEmailModal;