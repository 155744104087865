import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import authService from '../api/AuthorizeService';
import { Redirect, Link, RouteComponentProps } from 'react-router-dom';
import { Organization } from '../models/organization';
import { Row, Col, Container, Spinner } from 'react-bootstrap';
import httpService from '../api/HttpService';
import { CreateOrganizationDTO } from '../models/create-organization-dto';
import { PriceCalculator } from './price-calculator';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { OrganizationPublicDTO } from '../models/organization-public-dto';
import { PriceTable } from './price-table';

interface MatchParams {
    id: string;
}

interface Props extends RouteComponentProps<MatchParams> {

}

enum ConnectionStatus {
    Loading,
    OK,
    Failed
}

interface State {
    clubName?: string;
    address?: string;
    city?: string;
    country?: string;
    shortName?: string;
    pincode?: string;
    numberOfTables?: number;
    connectionStatus?: ConnectionStatus;
    waiting: boolean;
    organizations?: OrganizationPublicDTO[];
    selectedPlan?: string,
    currency?: string;
    billingPeriod?: string;
    averagePrice?: number;
    vatNumber?: string;
    shouldRedirect?: boolean;
}

export class Registration extends Component<Props, State> {

    private registrationId: string;
    private _apiPath: string = 'api/RegistrationRequests';
    private trial: boolean = false;
    private stripe: Stripe | null;

    constructor(props: Props) {
        super(props);
        this.registrationId = this.props.match.params.id;
        let urlSearch = new URLSearchParams(this.props.location.search);


        if (urlSearch.has("trial")) {
            this.trial = true;
        }

        this.state = { numberOfTables: 1, connectionStatus: ConnectionStatus.Loading, waiting: false };
        this.registerClick = this.registerClick.bind(this);

        this.stripe = null;

        this.loadStripeData();
    }

    componentDidMount() {
        this.loadData();
        this.loadOrganizationData();
    }

    private loadStripeData = async () => {
        // this.stripe = await loadStripe('pk_test_51IiP6NDWUqmX10zNvwEko4dJHyyyoIpu5PyMQ3Kf58QJyd0g5qsBFIYXGtDVOaPveiUrumJUeAxwuYuZhVlhKaMk00BSOII8Ez');
        this.stripe = await loadStripe('pk_live_51IiP6NDWUqmX10zN8UrsSvGsM4jSvB7OHW4ihVV0EWo4o4cUDQKsZaQ8tYj5IPAlweKvMMtHzHno1t8CR4bu4B9m00Op0eikaF');
    }


    private updatePrice = (numberOfTables?: number, selectedPlan?: string, currency?: string, billingPeriod?: string, averagePrice?: number): void => {
        this.setState({ numberOfTables: numberOfTables, selectedPlan: selectedPlan, currency: currency, billingPeriod: billingPeriod, averagePrice: averagePrice });
    }

    private loadData = async (): Promise<void> => {
        const response = await fetch(`${httpService.BaseUrl}${this._apiPath}/${this.registrationId}`);

        this.setState({
            connectionStatus: response.status === 200 ? ConnectionStatus.OK : ConnectionStatus.Failed
        });
    }

    private loadOrganizationData = async (): Promise<void> => {
        const organizations = await httpService.anonGet<OrganizationPublicDTO[]>("api/organizations/all");

        this.setState({
            organizations: organizations
        });
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value, waiting: this.state.waiting });
    }

    async registerClick() {


        if (this.state.address && this.state.city && this.state.clubName && this.state.country
            && this.state.pincode && this.state.shortName 
            && (this.trial || (this.state.numberOfTables
            && this.state.selectedPlan
            && this.state.currency))) {
            this.setState({ waiting: true });
            const orgDTO: CreateOrganizationDTO = {
                address: this.state.address,
                city: this.state.city,
                clubName: this.state.clubName,
                country: this.state.country,
                numberOfTables: this.state.numberOfTables ?? 0,
                pincode: this.state.pincode,
                shortName: this.state.shortName,
                vatNumber: this.state.vatNumber,
                requestTrial: this.trial
            };
            const resp = await httpService.postWithId<CreateOrganizationDTO>(this._apiPath, this.registrationId, orgDTO);
            if (resp.status === 201) {
                let data = await resp.json();
                console.log("Data", data);

                if (this.trial) {
                    alert("Thank you for requesting a trial! We will get back to you as soon as possible on the email you used to register with. If you have any questions please contact info@147board.com");
                }
                else {
                    await this.createCheckoutSession(data.organizationId, data.email);
                }
                this.setState({ waiting: false, shouldRedirect: this.trial });
            }
            else {
                this.setState({ waiting: false });
                alert("Error registering");
            }
        }
        else {
            alert("All fields must be filled out");
        }
    }

    private async createCheckoutSession(organizationId: string, email: string) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                plan: this.state.selectedPlan,
                numberOfTables: this.state.numberOfTables,
                currency: this.state.currency,
                organizationId: organizationId,
                billingPeriod: this.state.billingPeriod,
                email: email
            })
        };

        const response = await fetch(`${httpService.BaseUrl}api/payments/create-checkout-session`, requestOptions);

        if (response.status === 201) {
            const data = await response.json();
            this.stripe?.redirectToCheckout({
                sessionId: data.sessionId
            });
        }
    }

    private renderContactInformation(): JSX.Element {
        return (
            <div>
                <div className="header">
                    <h2>Contact information</h2>
                </div>
                <Form>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="formBasicClubName">
                                <Form.Label>Display name (shown on your live score page) {!this.state.clubName && <span className="text-danger"><strong>(*)</strong></span>} </Form.Label>
                                <Form.Control type="text" name="clubName" placeholder="Enter club name" value={this.state.clubName} onChange={this.handleChange} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formBasicAddress">
                                <Form.Label>Address {!this.state.address && <span className="text-danger"><strong>(*)</strong></span>}</Form.Label>
                                <Form.Control type="text" name="address" placeholder="Address..." value={this.state.address} onChange={this.handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="formBasicCity">
                                <Form.Label>City {!this.state.city && <span className="text-danger"><strong>(*)</strong></span>}</Form.Label>
                                <Form.Control type="text" name="city" placeholder="City..." value={this.state.city} onChange={this.handleChange} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="formBasicCountry">
                                <Form.Label>Country {!this.state.country && <span className="text-danger"><strong>(*)</strong></span>}</Form.Label>
                                <Form.Control type="text" name="country" placeholder="Country..." value={this.state.country} onChange={this.handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="formBasicVAT">
                                <Form.Label>VAT or CVR number (optional)</Form.Label>
                                <Form.Control type="text" name="vatNumber" placeholder="VAT number..." value={this.state.vatNumber} onChange={this.handleChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }

    checkShortname(shortname?: string) {
        if (shortname && this.state.organizations) {
            if (shortname.length < 3) {
                return { shortnameValidElement: <span className="text-danger">Must be at least 3 characters long</span>, shortnameValid: false };
            } else if (this.state.organizations.some(x => x.shortName.toLowerCase() === shortname.toLowerCase())) {
                return { shortnameValidElement: <span className="text-danger">Shortname is already taken</span>, shortnameValid: false };
            }
            else {
                return { shortnameValidElement: <span className="text-success">Available</span>, shortnameValid: true };
            }
        }
        return { shortnameValidElement: <div></div>, shortnameValid: false };
    }

    checkPincode(pincode?: string) {
        if (pincode) {
            if (pincode.length < 4) {
                return { pincodeValidElement: <span className="text-danger">Must be at least 4 digits</span>, pincodeValid: false };
            }
            else {
                return { pincodeValidElement: <span className="text-success">Looks good!</span>, pincodeValid: true };
            }
        }
        return { pincodeValidElement: <span></span>, pincodeValid: false };
    }

    private renderAppLogin(): JSX.Element {

        let shortName = this.state.shortName ?? "shortname";
        if (shortName.length == 0) {
            shortName = "shortname";
        }

        const { shortnameValidElement, shortnameValid } = this.checkShortname(this.state.shortName);
        const { pincodeValidElement, pincodeValid } = this.checkPincode(this.state.pincode);

        return (
            <div>
                <div className="header">
                    <h2>App login</h2>
                </div>
                <p>Choose a shortname for your club. This is the name that you will use to login on the Scoreboard software,
                    and it will be used in the urls for your live score page, as <span className="code-format">/live/<i>{shortName}</i></span>
                </p>
                <Form>
                    <Row>
                        <Col md={3}>
                            <Form.Group controlId="formBasicClubName">
                                <Form.Label>Club shortname / app login name</Form.Label>
                                <Form.Control type="text" name="shortName" placeholder="Enter club shortname" value={this.state.shortName} onChange={this.handleChange} />
                                <div className="input-validation">
                                    {shortnameValidElement}
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md={3} xs="auto">
                            <Form.Group controlId="formBasicAddress">
                                <Form.Label>Pin code</Form.Label>
                                <Form.Control type="number" name="pincode" placeholder="Pincode..." value={this.state.pincode} onChange={this.handleChange} />
                                <div className="input-validation">
                                    {pincodeValidElement}
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }

    private isFilledCorrectly(isTrial: boolean): boolean {
        const { shortnameValidElement, shortnameValid } = this.checkShortname(this.state.shortName);
        const pincodeValid = this.state.pincode ? this.state.pincode.length >= 4 : false;
        const planSelected = isTrial || this.state.selectedPlan !== undefined;

        const disabledButton = this.state.waiting || !shortnameValid || !pincodeValid || !planSelected;
        return disabledButton;
    }

    private renderRequestTrial(): JSX.Element {
        const disabledButton = this.isFilledCorrectly(true)

        return (
            <div>
                <Button className="mt-4" variant="primary" onClick={this.registerClick} disabled={disabledButton}>
                    {this.state.waiting && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    }
                    <span className="mx-2">Finish</span>
                </Button>
            </div>
        );
    }

    private renderSelectPlan(): JSX.Element {
        const disabledButton = this.isFilledCorrectly(false)
        let priceElement = <div></div>;

        if (this.state.averagePrice && this.state.numberOfTables) {

            let currency = "€";
            let currencyModifier = 1;
            if (this.state.currency) {
                console.log("Hello");
                if (this.state.currency === "dkk") {
                    console.log("Hello2");
                    currencyModifier = 7.5;
                    currency = "kr."
                    console.log("Hello3");
                }
            }

            let monthyPrice = this.state.averagePrice * this.state.numberOfTables * currencyModifier * (this.state.billingPeriod === "year" ? 10 : 1);
            const price = monthyPrice.toFixed(monthyPrice % 1 != 0 ? 2 : 0).replace('.', this.state.currency && this.state.currency === "dkk" ? ',' : '.');

            priceElement = <h4>Total: {price} {currency} / {this.state.billingPeriod}</h4>
        }

        return (
            <div>
                <div className="header">
                    <h2>Select plan</h2>
                </div>
                <p>Select plan and number of tables</p>
                <PriceTable editable={true} selectable={true} onUpdate={this.updatePrice} />
                {/* <PriceCalculator editable={true} onUpdate={this.updatePrice} /> */}

                {priceElement}

                <Button className="mt-4" variant="primary" onClick={this.registerClick} disabled={disabledButton}>
                    {this.state.waiting && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    }
                    <span className="mx-2">Continue to payment</span>
                </Button>
            </div>
        )
    }

    private renderLoading(): JSX.Element {
        return (
            <div>
                Loading...
            </div>
        );
    }

    private renderFailed(): JSX.Element {
        return (
            <div>
                Failed to find registration
            </div>
        );
    }

    private renderPage(): JSX.Element {

        return (
            <div>
                <div className="header">
                    <h1>Complete registration</h1>
                </div>
                <p>Fill out details about your club:</p>

                {this.renderContactInformation()}
                {this.renderAppLogin()}


                {this.trial && this.renderRequestTrial()}
                {!this.trial && this.renderSelectPlan()}



            </div>
        );
    }

    render() {
        if (this.state.shouldRedirect) {
            return (
               <Redirect to='/login' />
            );
        }
        if (this.state.connectionStatus && this.state.connectionStatus === ConnectionStatus.OK) {
            return this.renderPage();
        }
        if (this.state.connectionStatus && this.state.connectionStatus === ConnectionStatus.Failed) {
            return this.renderFailed();
        }
        return this.renderLoading();
    }
}