import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


interface Props {
    onAddClick?: (name: string, password: string, confirmPassword: string) => void;
    onClose?: () => void;
    show: boolean;
}

export const AddUserModal = (props: Props) => {
    const p = props;
    const [input, setInput] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    return (
        <div>
            {
                props.show ?
                    <Modal show={props.show} onHide={props.onClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add app login</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>                      
                            <Form>                               
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" name="username" placeholder="Enter email" value={input} onChange={e => setInput(e.target.value)}  />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" name="password" placeholder="Password..." value={password} onChange={e => setPassword(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="formBasicConfirmPassword">
                                    <Form.Label>Confirm password</Form.Label>
                                    <Form.Control type="password" name="confirmPassword" placeholder="Confirm password..." value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" disabled={input.length == 0 || password.length == 0 || confirmPassword.length == 0} onClick={() => props.onAddClick && props.onAddClick(input, password, confirmPassword)}>
                                Create
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    :
                    <div></div>
            }
        </div>
    );
};

