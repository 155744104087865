import React from 'react';
import { Container } from 'react-bootstrap';

interface Props {}

export const Footer = (props: Props) => {
    return (
        <div className="footer">
            <Container>
                <div className="copyright">Copyright 2020 - 2021 Daniel Jallov &nbsp; &nbsp;  | &nbsp; &nbsp;  <a href="mailto:info@147board.com">info@147board.com</a> </div>
            </Container>
        </div>
    );
}