import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import authService from '../api/AuthorizeService';
import { Link, Redirect } from 'react-router-dom';
import { FormControl, Spinner } from 'react-bootstrap';

interface LoginProps {

}

interface LoginState {
    username?: string;
    password?: string;
    wrongPassword?: boolean;
    waiting: boolean;
}

export class Login extends Component<LoginProps, LoginState> {

    constructor(props: LoginProps) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

        console.log("Is auth: " + authService.isAuthenticated());

        authService.subscribe(() => {
            this.setState({});
        })

        this.state = {
            username: '',
            password: '',
            wrongPassword: false,
            waiting: false
        };
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        this.setState({ [event.target.name]: event.target.value, waiting: this.state.waiting });
        this.setState({ wrongPassword: false });
    }

    async handleClick() {
        console.log(authService.test("Hej"));

        this.setState({ waiting: true });

        let success: boolean = await authService.authenticate(this.state.username!, this.state.password!);
        this.setState({ wrongPassword: !success, waiting: false });

        console.log("Success: ", success);
        console.log("State:", this.state);
    }

    private handleAnswerChange = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            this.handleClick();
        }
    }

    render() {
        let isAuth = authService.isAuthenticated();

        if (isAuth) {
            if (authService.isSuperAdmin()) {
                return (<Redirect to="/admin"></Redirect>);
            }
            return (
                <Redirect to="/dashboard"></Redirect>
            );
        }

        return (
            <div>
                <div className="header">
                    <h1>Sign in</h1>
                </div>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" name="username" placeholder="Enter email" value={this.state.username} onChange={this.handleChange} onKeyPress={this.handleAnswerChange} />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" placeholder="Password..." value={this.state.password} onChange={this.handleChange} onKeyPress={this.handleAnswerChange} />
                    </Form.Group>

                    <Button className="mt-1" variant="primary" onClick={this.handleClick} disabled={this.state.waiting}>
                        {this.state.waiting && <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        }
                        <span className="mx-2">Sign in</span>
                    </Button>
                    <Link className="ml-4" to={`/reset-password/${this.state.username}`}>Forgot password?</Link>
                </Form>
                <div className="pt-2">
                    {this.state.wrongPassword && <span className="text-danger"><b>Wrong username/password</b></span>}
                </div>
            </div>
        );
    }
}