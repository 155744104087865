import { LiveScore } from "../models/livescore";
import httpService from "./HttpService";

export class LivescoreService {

    private _tableId: string;
    private _delay: number;
    private interval: NodeJS.Timeout;
    private updateFrequency: number;
    private callback: (livescore: LiveScore) => void;

    private scoreList: LiveScore[];
    private queueLength: number;

    public constructor(tableId: string, updateFrequency: number, delay: number, callback: (livescore: LiveScore) => void) {
        this._tableId = tableId;
        this._delay = delay;
        this.updateFrequency = updateFrequency * 1000;
        this.callback = callback;

        this.interval = setInterval(() => console.log(), 10000);
        this.scoreList = [];
        this.queueLength = delay / updateFrequency;

    }

    start(): void {
        this.loadData();
        clearInterval(this.interval);
        this.interval = setInterval(() => this.loadData(), this.updateFrequency);
    }

    stop(): void {
        clearInterval(this.interval);
    }

    async loadData(): Promise<void> {
        const currentScore = await this.get();

        this.scoreList.push(currentScore);

        this.callback(this.getNext());
    }

    private getNext(): LiveScore {
        if (this.scoreList.length < this.queueLength) {
            return this.scoreList[0];
        }
        else {
            return this.scoreList.shift()!;
        }
    }

    async get(): Promise<LiveScore> {

        try {
            const liveScore = await httpService.anonGet<LiveScore[]>(`api/LiveScores?licenseId=${this._tableId}`);

            if (liveScore?.length > 0) {
                return liveScore[0];
            }
        }
        catch (e) {

        }
        let testScore: LiveScore = {
            distance: 3,
            tableName: "Test",
            player1Score: 43,
            organizationName: "Test",
            player1FirstName: "Player",
            player1LastName: "1",
            player1Break: 0,
            player1BallsInBreak: "[]",
            player1FramesWon: 2,
            player1HighBreaks: 35,
            player1IsActive: true,
            player2BallsInBreak: "[]",
            player2Break: 0,
            player2FirstName: "Player",
            player2LastName: "2",
            player2FramesWon: 1,
            player2IsActive: false,
            player2HighBreaks: 32,
            player2Score: 12,
            licenseEntityId: "",
        };
        return testScore;
    }
}