import React, { Component, ChangeEvent } from 'react';
import httpService from '../api/HttpService';
import { Organization } from '../models/organization';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { OrganizationInfo } from './organization-info';
import authService from '../api/AuthorizeService';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { PriceCalculator } from './price-calculator';
import { Row, Col } from 'react-bootstrap';
import { ConfirmModal } from './confirm-modal';
import { PriceTable } from './price-table';
import { Image } from "../models/image";
import { PaymentFailedBanner } from './payment-failed-banner';


interface MatchParams {
    id: string;
}

interface Props extends RouteComponentProps<MatchParams> {

}

interface State {
    organizationId: string;
    organization?: Organization;
    clubName?: string;
    address?: string;
    city?: string;
    country?: string;
    isSubmitted: boolean;
    file?: FormData;
    unlockedAccount: boolean;
    editPlan: boolean;
    showConfirmChangePlan: boolean;
}

export class EditOrganization extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        let orgId: string = this.props.match.params.id;

        this.state = {
            organizationId: orgId,
            isSubmitted: false,
            unlockedAccount: false,
            editPlan: false,
            showConfirmChangePlan: false
        };

        if (!authService.isAuthenticated() || orgId.length <= 0) {
            this.props.history.push('/login');
            return;
        }

        if (!authService.isAdmin()) {
            this.props.history.push('/dashboard');
            return;
        }

        console.log(this.state);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        this.setState({
            organizationId: this.state.organizationId,
            isSubmitted: this.state.isSubmitted,
            unlockedAccount: this.state.unlockedAccount,
            editPlan: this.state.editPlan,
            showConfirmChangePlan: this.state.showConfirmChangePlan,
            [event.target.name]: event.target.value
        })
    }

    async handleClick() {
        let organization = this.state.organization;
        if (organization) {
            organization.clubName = this.state.clubName;
            organization.address = this.state.address;
            organization.city = this.state.city;
            organization.country = this.state.country;

            if (authService.isSuperAdmin()) {
                organization.unlockedAccount = this.state.unlockedAccount;
            }

            this.saveOrganization(organization);
            this.setState({ isSubmitted: true });
        }
        console.log(this.state);

    }

    async handleUpload(e: ChangeEvent<HTMLInputElement>) {

        e.preventDefault();
        if (e && e.target && e.target.files) {
            let form = new FormData();
            for (var index = 0; index < e.target.files.length; index++) {
                var element = e.target.files[index];
                form.append('image', element);
                form.append('fileName', element.name);
                console.log(element);
            }

            console.log(form);
            this.setState({
                file: form
            });
        }
    }

    private handleUnlockedAccountChange = (event: React.MouseEvent<HTMLInputElement>) => {
        this.setState({
            organizationId: this.state.organizationId,
            isSubmitted: this.state.isSubmitted,
            unlockedAccount: !this.state.unlockedAccount,
        });
    }

    renderUnlockedAccount(): JSX.Element {
        if (authService.isSuperAdmin()) { //TODO: Should be isSuperAdmin
            return (
                <div>
                    <span>Unlocked account</span>
                    <input className="form-check-input ml-4" name="livePack" checked={this.state.unlockedAccount} onClick={this.handleUnlockedAccountChange} type="checkbox"></input>
                </div>
            );
        }
        else {
            if (this.state.unlockedAccount) {
                return <p className="text-success"><b>Unlocked account</b></p>
            }
        }
        return <div></div>
    }

    private confirmChangeClick = () => {
        this.setState({showConfirmChangePlan: true, editPlan: false})
    }

    private manageBilling = async () => {
        const token = authService.getBearerToken();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        };

        const response = await fetch(`${httpService.BaseUrl}api/payments/create-customer-portal-session?orgId=${this.state.organizationId}`, requestOptions);
        if (response.status === 201) {
            const data = await response.json();
            window.location.href = data.url;
        }
        else {
            alert("Something went wrong. Contact info@147board.com for assistance.")
        }
    }


    render() {
        if (this.state.isSubmitted) {
            return (
                <Redirect to={`/dashboard/${this.state.organizationId}`} />
            );
        }
        return (
            <div>
                 <PaymentFailedBanner organizationId={this.state.organizationId} />
                <div className="header">
                    <h1>Edit organization</h1>
                </div>
                {this.state.organization && this.renderUnlockedAccount()}
                {this.state.organization &&
                    <Form>
                        <Form.Group controlId="formBasicClubname">
                            <Form.Label>Club name</Form.Label>
                            <Form.Control type="email" name="clubName" placeholder="Enter club name" value={this.state.clubName} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formBasicAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="text" name="address" placeholder="Enter address" value={this.state.address} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formBasicCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" name="city" placeholder="Enter city" value={this.state.city} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group controlId="formBasicCountry">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" name="country" placeholder="Enter country" value={this.state.country} onChange={this.handleChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Upload logo</Form.Label>
                            <Row>
                                <Col md={6}>
                                    <input name="Avatar" id='img' type="file" accept=".png, .jpg, .jpeg, .bmp" className="form-control" onChange={(e) => this.handleUpload(e)} />
                                </Col>
                                <Col md={6}>
                                    <Button variant="primary" onClick={this.upload}>
                                        Upload
                                </Button>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Row>                           
                            <Col md={6}>
                            <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Billing information</h5>                                      
                                        <div>
                                            {this.state.organization?.paymentStatus === "paid" || this.state.organization?.unlockedAccount ? 
                                            <p className="text-success"><b>Paid</b></p> 
                                            : 
                                            <p className="text-danger"><b>Not paid</b></p>}
                                            
                                            <p>Plan: {this.state.organization?.plan}, {this.state.organization?.organizationOptions?.numberOfLicenses} licenses </p>
                                            <p>Last invoice paid: {this.state.organization?.latestInvoicePaid}</p>
                                            <p>Next payment due: {this.state.organization?.nextInvoice}</p>
                                        </div>
                                        <Button onClick={this.manageBilling}>Manage billing</Button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <img src="/logo512.png" width={100} height={100}></img>
                            </Col>
                        </Row>
                        <Row className="mt-4">

                            <Col>
                                <Button variant="primary" onClick={this.handleClick}>
                                    Save
                            </Button>
                            </Col>
                        </Row>
                    </Form>
                }
                <ConfirmModal show={this.state.showConfirmChangePlan} message="Are you sure you want to change your plan? New price is " onClose={() => this.setState({showConfirmChangePlan: false})} />

            </div>
        );
    }

    private upload = async () => {
        if (this.state.file) {
            console.log("Uploading", this.state.file);
            let response = await httpService.upload<Image>('api/organizations/upload', this.state.file);

            if (this.state.organization) {
                const organization = this.state.organization;
                organization.logoUrl = response.url;
                await this.saveOrganization(organization); 
            }
            console.log("Response", response);
        }
    }

    private loadData = async () => {
        const url = `api/organizations/${this.state.organizationId}`;
        let org = await httpService.get<Organization>(url);

        this.setState({ organization: org, clubName: org.clubName, address: org.address, city: org.city, country: org.country, unlockedAccount: org.unlockedAccount });
    }

    private saveOrganization = async (organization: Organization) => {
        let response = await httpService.put<Organization>('api/organizations', organization.id, organization);

    }
}